import Link from "next/link";
import { useRouter } from "next/router";
import clsx from "clsx";

import { Card } from "@chef/components";
import { useViewport } from "@chef/hooks";
import { useMeQuery } from "@chef/state-management";
import { isEmptyArray } from "@chef/utils/array";

import { isActivePath } from "../../utils";
import { tabBar } from "./tabBarItems";

export const BottomTabBar = () => {
  const { data: isLoggedIn } = useMeQuery();
  const router = useRouter();

  const [breakpoint] = useViewport();

  if (isEmptyArray(tabBar.items) || breakpoint !== "sm" || !isLoggedIn) {
    return null;
  }

  if (breakpoint === "sm") {
    document.body.classList.add("browser-intercom-bubble-bottom-margin");
  } else {
    document.body.classList.remove("browser-intercom-bubble-bottom-margin");
  }

  const navItems = tabBar.items;

  const gridCols = navItems.length > 4 ? "grid-cols-5" : "grid-cols-4";

  return (
    <div className="pb-24 md:hidden">
      <Card
        noPadding
        className="fixed bottom-0 left-0 right-0 z-10 w-full h-20 pr-2 print:hidden"
      >
        <nav
          className={clsx(
            "grid content-center w-full h-full  text-center",
            gridCols,
          )}
          id="tab-bar"
        >
          {navItems.slice(0, 5).map(({ label, href, icon }, index, items) => {
            const isActive = isActivePath(
              router.pathname,
              href,
              items.map((i) => i.href),
            );

            return (
              <Link
                key={href}
                href={href}
                passHref
                className="col-span-1 text-center"
                id={"bottom_tab_bar_" + index}
              >
                <div
                  className={clsx(
                    isActive && "opacity-100",
                    !isActive && "opacity-60",
                  )}
                >
                  <div
                    className={clsx(
                      isActive && "opacity-100",
                      "w-10 h-10 m-auto align-bottom content-center",
                    )}
                  >
                    {icon}
                  </div>

                  <div className="text-sm">{label}</div>
                </div>
              </Link>
            );
          })}
        </nav>
      </Card>
    </div>
  );
};
