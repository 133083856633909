import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { Button, Card } from "@chef/components";
import {
  selectSchedulerModal,
  setWeekSchedulerEditorModal,
  useCreateOrUpdateSchedulerMutation,
} from "@chef/state-management";
import { useCalendarData } from "@chef/state-management/hooks";
import { useExtendedForm, useIntercom } from "@chef/hooks";
import { getCurrentWeekDataAndBasketFromCalendar } from "@chef/state-management/helpers";
import { formatDate } from "@chef/helpers";
import { TIME } from "@chef/constants";
import { Loading } from "@chef/icons/small";

import { TimeblocksCheckboxRadio } from "./TimeblocksCheckboxRadio";
import { AddressDropdown } from "./AddressDropdown";
import type { WeekSchedulerFormData } from "./types";
import { Modal } from "../modal/Modal";

import { intl } from "./WeekSchedulerEditor.Intl";

const schema = yup.object().shape({
  addressId: yup.string().required(),
  timeblockId: yup.number().nullable(),
});

const RANGE = 12;

const checkIfElementHasVerticalScroll = (
  ref: React.RefObject<HTMLDivElement>,
) => {
  if (!ref.current) {
    return false;
  }

  return ref.current.scrollHeight > ref.current.clientHeight;
};

export const WeekSchedulerEditor = () => {
  const { hideBubble, showBubble } = useIntercom();

  const dispatch = useDispatch();
  const { open, week, year } = useSelector(selectSchedulerModal);

  const [isCanceling, setIsCanceling] = useState(false);
  const [hasVerticalScroll, setHasVerticalScroll] = useState(false);

  const scrollRef = useRef<HTMLDivElement>(null);

  const [createOrUpdateScheduler] = useCreateOrUpdateSchedulerMutation();

  const { calendar } = useCalendarData({
    week,
    year,
    range: RANGE,
    skip: !open,
  });

  const formControls = useExtendedForm<WeekSchedulerFormData>({
    resolver: yupResolver(schema),
  });

  const { setValue, formState, handleSubmit } = formControls;

  const calendarWeek = calendar
    ? getCurrentWeekDataAndBasketFromCalendar({
        calendar,
        week,
        year,
      })
    : null;

  const hasDelivery = calendarWeek?.basket.shippingDetails.hasDelivery;

  const currentAddressId =
    calendarWeek?.basket.shippingDetails.addressInfo.addressId;

  const plannedDeliveryInformation =
    calendarWeek?.basket.shippingDetails.plannedDeliveryInformation;

  const currentCutoff = plannedDeliveryInformation?.cutOffDateTime;
  const currentTimeblockId = plannedDeliveryInformation?.actualTimeblockId;

  const handleClose = () => {
    dispatch(setWeekSchedulerEditorModal({ open: false }));
  };

  useEffect(() => {
    if (open) {
      hideBubble();
    } else {
      showBubble();
    }
  }, [hideBubble, showBubble, open]);

  useEffect(() => {
    if (!open) {
      return;
    }

    window.addEventListener("resize", () => {
      setHasVerticalScroll(checkIfElementHasVerticalScroll(scrollRef));
    });

    setHasVerticalScroll(checkIfElementHasVerticalScroll(scrollRef));

    return () => {
      window.removeEventListener("resize", () => {
        setHasVerticalScroll(checkIfElementHasVerticalScroll(scrollRef));
      });
    };
  }, [open]);

  useEffect(() => {
    if (!currentAddressId) {
      return;
    }

    setValue("addressId", currentAddressId);
  }, [currentAddressId, setValue]);

  useEffect(() => {
    if (!currentTimeblockId) {
      return;
    }

    setValue("timeblockId", currentTimeblockId);
  }, [currentTimeblockId, setValue]);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  if (!calendarWeek) {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        size="md"
        name="week-scheduler-editor"
      >
        <Card className="flex flex-col items-center justify-center w-full min-h-screen md:mt-28 md:min-h-0">
          <Loading className="animate-spin" />
        </Card>
      </Modal>
    );
  }

  const onSubmit = async (data: WeekSchedulerFormData) => {
    if (data.timeblockId === null) {
      return;
    }

    await createOrUpdateScheduler({
      week,
      year,
      addressId: data.addressId,
      timeblockId: data.timeblockId,
      delivery: true,
    }).unwrap();

    handleClose();
  };

  const handleCancelDelivery = async () => {
    setIsCanceling(true);

    await createOrUpdateScheduler({
      week: week,
      year: year,
      delivery: !hasDelivery,
    }).unwrap();
    setIsCanceling(false);
    handleClose();
  };

  return (
    <Modal
      open={open}
      animation="fly-up"
      gesture="pull-down-to-close"
      onClose={handleClose}
      closeable={false}
      backToTop={false}
      size="md"
      name="week-scheduler-editor"
      ref={scrollRef}
    >
      <Card
        noPadding
        className="relative flex flex-col w-full min-h-screen overflow-y-visible text-base text-center mt-28 md:min-h-0"
      >
        <h1 className="mt-12 text-xl">
          <strong>
            {hasDelivery
              ? `${intl.MANAGE_DELIVERY_WEEK} ${week}`
              : `${intl.BOOK_DELIVERY_FOR_WEEK}  ${week}`}
          </strong>
        </h1>

        <p className="px-4 mt-2 text-sm md:px-20">
          {hasDelivery
            ? intl.DEADLINE_FOR_DELIVERY_CHANGE
            : intl.CONFIRM_DELIVERY_DETAILS}{" "}
          <strong>
            {currentCutoff &&
              hasDelivery &&
              formatDate(
                new Date(currentCutoff),
                `${TIME.DEFAULT_DATE_FORMAT} '${intl.TIME_MARKER}' HH:mm`,
              ).toLocaleLowerCase()}
          </strong>
        </p>

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col grow">
          <div className="px-4 pb-8 md:px-20 grow">
            <div className="mt-8 md:mt-6">
              <AddressDropdown
                formControls={formControls}
                handleModalClose={handleClose}
              />
            </div>
            <div className="mt-8 md:mt-6">
              <TimeblocksCheckboxRadio
                formControls={formControls}
                week={week}
                year={year}
                calendarTimeblockId={currentTimeblockId}
              />
            </div>
          </div>

          <div
            className={clsx(
              "sticky bottom-0 flex flex-col justify-center text-center",
              hasVerticalScroll && "shadow-fab-xl",
            )}
          >
            <div
              className={clsx(
                "px-4 py-4 md:px-20 rounded",
                hasDelivery ? "bg-grey-3" : "bg-background",
              )}
            >
              <Button
                type="submit"
                loading={formState.isSubmitting}
                disabled={
                  formControls.getValues("timeblockId") === null || isCanceling
                }
                primary
                full
                small
              >
                {hasDelivery ? intl.SAVE_AND_CLOSE : intl.ORDER_DELIVERY}
              </Button>
            </div>

            {hasDelivery && (
              <div className="px-4 pt-4 rounded bg-grey-3 md:px-20">
                <strong>
                  {intl.DO_YOU_WANT_TO_CANCEL_THE_DELIVERY_FOR_WEEK} {week}?
                </strong>

                <div>
                  <Button
                    type="button"
                    onClick={handleCancelDelivery}
                    outlined
                    className="mt-4 bg-white"
                    loading={isCanceling}
                    small
                  >
                    {intl.CANCEL_DELIVERY}
                  </Button>
                </div>
                <button
                  type="button"
                  className="mt-4 mb-6 underline"
                  onClick={handleClose}
                >
                  <strong>{intl.CANCEL}</strong>
                </button>
              </div>
            )}
          </div>
        </form>
      </Card>
    </Modal>
  );
};
