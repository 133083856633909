/**
 * @module parity
 * @category utils
 */

/**
 * Check to test if number is even
 * @param {Number} number to check
 * @returns {Boolean} true or false
 * @example isEven(2); // returns true
 * @example isEven(3); // returns false
 */
const isEven = (n: number | string | null): boolean => {
  if (!n) {
    return false;
  }
  return +n % 2 === 0;
};

/**
 * Check to test if number is odd
 * @param {Number} number to check
 * @returns {Boolean} true or false
 * @example isOdd(3); // returns true
 * @example isOdd(2); // returns false
 */
const isOdd = (n: number | string | null): boolean => {
  if (!n) {
    return false;
  }

  return Math.abs(+n % 2) === 1;
};

export { isEven, isOdd };
