import { IconChefHat, IconToolsKitchen2 } from "@tabler/icons-react";

import { RecipeAndStepsQuery } from "@chef/state-management";
import { BRAND_NAME } from "@chef/constants";

import { RecipeStep } from "./RecipeStep";
import { splitRecipeStepString } from "../../utils/recipe";

import { brand } from "./RecipeStepSections.Intl";

interface RecipeStepSectionsProps {
  stepSections: RecipeAndStepsQuery["recipeAndSteps"]["instructions"]["portions"][number]["stepSections"];
  enableTrackingSteps?: boolean;
}

export const RecipeStepSections = ({
  stepSections = [],
  enableTrackingSteps,
}: RecipeStepSectionsProps) => {
  return (
    <>
      <div>
        {stepSections.map((stepSection) =>
          stepSection.steps.map((recipeStep) => {
            const { heading, description } = splitRecipeStepString(
              recipeStep.step,
            );

            return (
              <RecipeStep
                key={recipeStep.order}
                heading={heading}
                description={description}
                order={recipeStep.order}
                type={enableTrackingSteps ? "progress" : "default"}
              />
            );
          }),
        )}
      </div>
      {enableTrackingSteps && (
        <div className="flex items-center gap-2 mt-2">
          {BRAND_NAME === "AMK" ? (
            <IconToolsKitchen2 size={30} height={30} />
          ) : (
            <IconChefHat size={30} height={30} />
          )}

          <strong>{brand.ENJOY_THE_FOOD}</strong>
        </div>
      )}
    </>
  );
};
