import { companyId } from "@chef/constants";
import { api } from "../graphql/api";

export interface IValidateGiftcard {
  message: string;
  amount: number;
}

export interface IValidateGiftcardArgs {
  giftcardCode: string;
}

export interface IValidateBasketArg {
  variationId: string;
  quantity: number;
}

export interface IValidateTelephoneInUse {
  telephone: string;
}

export interface IValidateEmailInUse {
  email: string;
}
export interface IValidateSSNInUse {
  ssn: string;
}
export interface IApartmentModel {
  name: string;
  floor: string;
  door: string;
}

export interface IDigitalKeyProvider {
  addressDigitalKeyProviderId?: string;
  name?: string;
}

export interface IAddressValidationModel {
  searchText?: string | null;
  streetName: string;
  postalCode: string;
  postalArea: string;
  streetNumber: string;
  letterExtra?: string;
  mode?: number;
  addressName?: string;
  apartments?: IApartmentModel[];
  digitalKeyProvider?: IDigitalKeyProvider | null;
  latitude?: number;
  longitude?: number;
}
export interface IAddressValidationResponse {
  value: IAddressValidationModel[];
}

export interface IAddressValidationArgs {
  queryText?: string;
  mode: number;
  streetName?: string;
  postalArea?: string;
  streetNumber?: string;
  letterExtra?: string;
  postalCode?: string;
  fetchCoordinates?: boolean;
}

interface IBusinessAddressArgs {
  street: string;
  city: string;
  postalCode: string;
}

export const validateApi = api.injectEndpoints({
  endpoints: (builder) => ({
    validateGiftcard: builder.query<IValidateGiftcard, IValidateGiftcardArgs>({
      query: (args) => ({
        url: "/validate/giftcard",
        method: "POST",
        body: { ...args, companyId },
      }),
    }),

    validateBasket: builder.mutation<void, IValidateBasketArg[]>({
      query: (variations) => ({
        url: "/validate/basket",
        method: "POST",
        body: { variations, companyId },
      }),
    }),

    validateTelephoneInUse: builder.query<void, IValidateTelephoneInUse>({
      query: ({ telephone }) => ({
        url: "/validate/telephoneInUse",
        method: "POST",
        body: { telephone, companyId },
      }),
    }),

    // https://gg-developer.developer.azure-api.net/api-details#api=cms-frontend-int-pub&operation=UserInformation_EmailInUse
    validateEmailInUse: builder.query<void, IValidateEmailInUse>({
      query: ({ email }) => ({
        url: "/validate/emailInUse",
        method: "POST",
        body: { Email: email, CompanyId: companyId },
      }),
    }),

    validateSSNInUse: builder.query<void, IValidateSSNInUse>({
      query: ({ ssn }) => ({
        url: "/validate/ssnInUse",
        method: "POST",
        body: { Ssn: ssn, CompanyId: companyId },
      }),
    }),

    addressValidation: builder.query<
      IAddressValidationResponse,
      IAddressValidationArgs
    >({
      query: (params) => ({
        url: `/validate/address`,
        method: "GET",
        params: params,
      }),
    }),

    addressValidationHealthCheck: builder.query<void, void>({
      query: () => ({
        url: `/validate/address`,
        method: "HEAD",
      }),
    }),
    b2bAddressValidation: builder.mutation<any, IBusinessAddressArgs[]>({
      query: (body) => ({
        url: `/validate/b2bAddress`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useValidateGiftcardQuery,
  useLazyValidateGiftcardQuery,
  useValidateBasketMutation,
  useLazyValidateTelephoneInUseQuery,
  useLazyValidateEmailInUseQuery,
  useLazyValidateSSNInUseQuery,
  useAddressValidationQuery,
  useLazyAddressValidationQuery,
  useAddressValidationHealthCheckQuery,
  useB2bAddressValidationMutation,
} = validateApi;
