import { UseFormReturn } from "react-hook-form";
import { useEffect, useMemo } from "react";

import { Checkbox, Skeleton } from "@chef/components";
import { TIMEBLOCK_TYPE_IDS } from "@chef/constants";
import { formatDate, formatTimeInterval, padPostalCode } from "@chef/helpers";
import {
  TimeblocksByRangeWithGeoQuery,
  useBillingQuery,
  useTimeblocksByRangeWithGeoQuery,
} from "@chef/state-management";
import { isEqualStrings } from "@chef/utils/equal";
import { isEmptyArray } from "@chef/utils/array";

import type { WeekSchedulerFormData } from "./types";
import { intl } from "./WeekSchedulerEditor.Intl";

interface TimeblocksDropdownProps {
  formControls: UseFormReturn<WeekSchedulerFormData>;
  week: number;
  year: number;
  calendarTimeblockId: number | undefined;
}

type Timeblock =
  TimeblocksByRangeWithGeoQuery["timeblocksByRangeWithGeo"]["weeks"][number]["timeblocks"][number];

// Deduplicate timeblocks by these conditions:
// - equal timeblockId
// - equal fallbackTimeblockId when fallbackTimeblockId is not null
const deduplicateTimeblocks = (timeblocks: Timeblock[]) => {
  const deduplicatedTimeblocks: Timeblock[] = [];

  for (const timeblock of timeblocks) {
    if (timeblock.noDelivery && timeblock.fallbackTimeblockId === null) {
      continue;
    }

    const idx = deduplicatedTimeblocks.findIndex((t) => {
      if (t.timeblockId === timeblock.timeblockId) {
        return true;
      }

      if (timeblock.fallbackTimeblockId) {
        return t.fallbackTimeblockId === timeblock.fallbackTimeblockId;
      }

      return false;
    });

    if (idx === -1) {
      deduplicatedTimeblocks.push(timeblock);
    }
  }

  return deduplicatedTimeblocks;
};

const createTimeblocksOptions = (args: { timeblocks: Timeblock[] }) => {
  const { timeblocks } = args;
  if (!timeblocks || timeblocks.length === 0) {
    return [];
  }

  return timeblocks
    .sort((a, b) => {
      const aDate = new Date(a.deliveryDate);
      aDate.setHours(+a.from.slice(0, 2));

      const bDate = new Date(b.deliveryDate);
      bDate.setHours(+b.from.slice(0, 2));

      return aDate.getTime() - bDate.getTime();
    })
    .map((t) => ({
      value: t.fallbackTimeblockId || t.timeblockId,
      label: `${formatDate(
        new Date(t.deliveryDate),
        "EEEE d. MMM",
      )} | ${formatTimeInterval(t.from, t.to, "m")}`,
    }));
};

export const TimeblocksCheckboxRadio = ({
  formControls,
  week,
  year,
  calendarTimeblockId,
}: TimeblocksDropdownProps) => {
  const { data: billingQuery } = useBillingQuery();
  const { setValue, getValues } = formControls;
  const addressId = getValues("addressId");

  const postalCode = billingQuery?.billing.addresses.find((a) =>
    isEqualStrings(a.addressId, addressId),
  )?.postalCode;

  const { data: timeblocksByRangeWithGeoQuery, isFetching } =
    useTimeblocksByRangeWithGeoQuery(
      {
        postalCode: padPostalCode(postalCode!),
        range: 1,
        timeblockTypeId: TIMEBLOCK_TYPE_IDS.NORMAL,
        week,
        year,
      },
      { skip: !postalCode },
    );

  const timeblocks = useMemo(() => {
    return deduplicateTimeblocks(
      timeblocksByRangeWithGeoQuery?.timeblocksByRangeWithGeo.weeks[0]
        ?.timeblocks || [],
    );
  }, [timeblocksByRangeWithGeoQuery]);

  const timeblockOptions = createTimeblocksOptions({ timeblocks });

  useEffect(() => {
    const matchingTimeblock = timeblocks.find(
      (t) =>
        t.timeblockId === calendarTimeblockId ||
        t.fallbackTimeblockId === calendarTimeblockId,
    );

    if (!matchingTimeblock) {
      setValue("timeblockId", timeblocks[0]?.timeblockId || null);
      return;
    }

    const matchingTimeblockId =
      matchingTimeblock.fallbackTimeblockId || matchingTimeblock.timeblockId;

    setValue("timeblockId", matchingTimeblockId);

    window.document
      .getElementById(`timeblock-${week}-${year}-${matchingTimeblockId}`)
      ?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  }, [
    timeblocksByRangeWithGeoQuery,
    calendarTimeblockId,
    timeblocks,
    setValue,
    addressId,
    week,
    year,
  ]);

  if (!timeblocksByRangeWithGeoQuery || !calendarTimeblockId || isFetching) {
    return <Skeleton height="40px" />;
  }

  if (isEmptyArray(timeblocks)) {
    return (
      <div className="my-2 text-sm text-center text-error">
        {intl.NO_DELIVERY_ON_SELECTED_ADDRESS_FOR_THIS_WEEK}
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-start mb-2 text-sm">
        <strong>{intl.DELIVERYDATE_AND_TIME}</strong>
      </div>

      {timeblockOptions.map((timeblock) => {
        const selectedValue = getValues("timeblockId");
        const isSelected = selectedValue
          ? timeblock.value === selectedValue
          : false;

        return (
          <Checkbox
            onChange={(e) => {
              e.currentTarget.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });

              setValue("timeblockId", timeblock.value);
            }}
            key={timeblock.value}
            id={`timeblock-${week}-${year}-${timeblock.value.toString()}`}
            name="timeblockId"
            type="radio"
            variant="selector"
            state="checked"
            className="my-2"
            checked={isSelected}
          >
            {timeblock.label}
          </Checkbox>
        );
      })}
    </>
  );
};
