import { useMemo } from "react";

import { PRODUCT_CATEGORY_IDS, PRODUCT_TYPE_IDS } from "@chef/constants";
import {
  getWeek,
  getYear,
  isPickAndMixProduct,
  nextDeliveryWeekDateObj,
} from "@chef/helpers";

import {
  useCalendarQuery,
  usePickAndMixQuery,
  usePreselectedProductsForWeekQuery,
  useProductCategoriesQuery,
  useProductsByCategoriesQuery,
} from "../graphql/generated";

import { IDeviationProduct } from "../features";

import {
  getBasketDataFromCalendar,
  getPreselectedDeviationForBasket,
  getPriceOfProductByVariation,
  getStandaloneProductCategoryIdsFromProductCategories,
} from "../helpers";

import { usePickAndMixPrice } from "./usePickAndMixPrice";

export const useCalendarData = (args: {
  week: number;
  year: number;
  skip?: boolean;
  range?: number;
}) => {
  const { week, year, range = 5 } = args;

  const skip = !week || !year || args.skip;

  const currentDate = nextDeliveryWeekDateObj();
  const currentWeek = getWeek(currentDate);
  const currentYear = getYear(currentDate);

  const { data: { calendar } = {}, ...calendarQuery } = useCalendarQuery(
    {
      week: currentWeek,
      year: currentYear,
      range,
    },
    { skip },
  );

  const { data: { preselectedProductsForWeek } = {}, ...preselectedQuery } =
    usePreselectedProductsForWeekQuery(
      {
        week,
        year,
      },
      { skip },
    );

  const { data: { pickAndMix } = {}, ...pickAndMixQuery } = usePickAndMixQuery(
    {
      productTypeId: PRODUCT_TYPE_IDS.PICKANDMIX,
      week,
      year,
    },
    { skip },
  );

  const { data: { productCategories } = {}, ...productCategoriesQuery } =
    useProductCategoriesQuery();

  const { data: { productsByCategories } = {}, ...productsByCategoriesQuery } =
    useProductsByCategoriesQuery(
      {
        categoryIds: [
          PRODUCT_CATEGORY_IDS.FINANCIAL,
          PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_IN,
          ...getStandaloneProductCategoryIdsFromProductCategories(
            productCategories || [],
          ),
        ],
        week,
        year,
      },
      { skip: skip || !productCategories },
    );

  const { getPriceOfPickAndMixProduct, ...pickAndMixPriceHook } =
    usePickAndMixPrice({
      week,
      year,
    });

  const basket = useMemo<IDeviationProduct[]>(() => {
    if (
      !calendar ||
      !preselectedProductsForWeek ||
      !pickAndMix ||
      !productsByCategories ||
      !productCategories
    ) {
      return [];
    }

    const calendarBasket = getBasketDataFromCalendar({
      calendar,
      week,
      year,
    });
    if (!calendarBasket) {
      return [];
    }

    const basketProducts: IDeviationProduct[] =
      calendarBasket.basketDetails.products.map((product) => ({
        productId: product.variation.product.productId,
        productTypeId: product.variation.product.productTypeId,
        quantity: product.quantity,
        variationId: product.variationId,
        price: getPriceOfProductByVariation(product.variation),
      }));

    const preselectedBasket = getPreselectedDeviationForBasket({
      preselectedProductsForWeek,
      productsByCategories,
      pickAndMixProducts: pickAndMix,
      selectedProducts: basketProducts,
    });

    // if preselectedBasket is not null, it means it was a mealbox basket and not a financial basket
    if (preselectedBasket) {
      return preselectedBasket;
    }

    // it was a financial basket, add proper pickAndMix prices
    return basketProducts.map((bp) => {
      if (!isPickAndMixProduct(bp)) {
        return bp;
      }

      return {
        ...bp,
        price:
          getPriceOfPickAndMixProduct({
            basketProducts: basketProducts,
            variationId: bp.variationId,
          }) || 0,
      };
    });
  }, [
    calendar,
    getPriceOfPickAndMixProduct,
    pickAndMix,
    preselectedProductsForWeek,
    productsByCategories,
    productCategories,
    week,
    year,
  ]);

  return {
    basket,

    calendar: calendarQuery.currentData?.calendar,
    preselectedProductsForWeek:
      preselectedQuery.currentData?.preselectedProductsForWeek,
    pickAndMix: pickAndMixQuery.currentData?.pickAndMix,
    productsByCategories:
      productsByCategoriesQuery.currentData?.productsByCategories,
    productCategories: productCategoriesQuery.currentData?.productCategories,

    calendarError: calendarQuery?.error,

    isLoading:
      calendarQuery.isLoading ||
      preselectedQuery.isLoading ||
      pickAndMixQuery.isLoading ||
      productsByCategoriesQuery.isLoading ||
      productCategoriesQuery.isLoading ||
      pickAndMixPriceHook.isLoading,
    isFetching:
      calendarQuery.isFetching ||
      preselectedQuery.isFetching ||
      pickAndMixQuery.isFetching ||
      productsByCategoriesQuery.isFetching ||
      productCategoriesQuery.isFetching ||
      pickAndMixPriceHook.isFetching,
    isError:
      calendarQuery.isError ||
      preselectedQuery.isError ||
      pickAndMixQuery.isError ||
      productsByCategoriesQuery.isError ||
      productCategoriesQuery.isError ||
      pickAndMixPriceHook.isError,
    isSuccess:
      calendarQuery.isSuccess &&
      preselectedQuery.isSuccess &&
      pickAndMixQuery.isSuccess &&
      productCategoriesQuery.isSuccess &&
      productsByCategoriesQuery.isSuccess,
  };
};
