import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { isActivePath } from "../..";
import { Logo } from "../Logo";
import { B2BDrawer } from "./Drawer";

const ORDER_CAMPAIGN_ID = "88235857-DDD6-466A-90B0-695FD867326F";

const MenuItems = () => {
  const router = useRouter();
  return (
    <nav className="hidden lg:block">
      <ul className="flex items-center justify-between gap-4">
        {headerItems.map(({ label, href }) => {
          const isActive = isActivePath(
            router.asPath,
            href,
            headerItems.map((i) => i.href),
          );

          return (
            <li key={`${href}-${label}`} className="relative">
              <Link
                href={href}
                passHref
                className={clsx(
                  !isActive &&
                    "hover:underline decoration-2 underline-offset-8 decoration-primary/75",
                )}
                id={label + "-link"}
              >
                {label}
              </Link>

              {isActive && <div className="absolute w-full h-0.5 bg-primary" />}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export const B2BVariant = () => {
  return (
    <div className="flex items-center justify-between h-15">
      <Link href="/bedrift" className="flex items-center">
        <Logo />
        <strong className="mt-1 ml-4 text-lg">BEDRIFT</strong>
      </Link>

      <div className="flex items-center gap-8">
        <MenuItems />

        <div className="flex items-center">
          <B2BDrawer />
        </div>
      </div>
    </div>
  );
};

const headerItems = [
  {
    label: "Ordreoversikt",
    href: `/bedrift/ordreoversikt/${ORDER_CAMPAIGN_ID}`,
  },
  // { label: "Bestill", href: `/bedrift/bestill/${ORDER_CAMPAIGN_ID}` },
];
