import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  isConceptPreference,
  isMealboxProduct,
  isTastePreference,
} from "@chef/helpers";
import { PREFERENCE_TYPE_IDS, PRODUCT_TYPE_IDS } from "@chef/constants";
import { difference, isNotEmptyArray } from "@chef/utils/array";
import { isEqualStrings, isNotEqualStrings } from "@chef/utils/equal";

import {
  convertFromBillingToUpdateBillingAgreement,
  useGeneratePersonalizedDeviationsMutation,
  useUpdateBillingAgreementMutation,
  useUpdatePreferencesMutation,
  useUpdateUserConsentsMutation,
  usePreselectorJobQuery,
  usePreselectorJobExpirationMutation,
} from "../features";
import { useBillingQuery } from "../graphql/generated";
import { api, tags } from "../graphql/api";

import type { AppDispatch } from "..";

type TriggerArgs = {
  week: number;
  year: number;
  variationId?: string;
  conceptPreferenceIds?: string[];
  tastePreferenceIds?: string[];
  requiredConsentIds?: string[];
  overrideDeviations?: boolean;
};

interface UsePreselector {
  onStageChange?: Partial<Record<STAGE, () => void>>;
  forcePoll?: boolean;
}

export enum STAGE {
  "IDLE",
  "UPDATING_CONSENTS",
  "UPDATING_PREFERENCES",
  "UPDATING_BILLING_AGREEMENT",
  "GENERATING_DEVIATIONS",
  "WAITING_FOR_COMPLETION",
  "COMPLETED",
  "COMPLETED_WITH_ALLERGEN",
  "FAILED",
}

export const usePreselector = ({
  onStageChange,
  forcePoll = false,
}: UsePreselector = {}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { data: billingQuery } = useBillingQuery();

  const [stage, _setStage] = useState<STAGE>(STAGE.IDLE);

  const shouldPoll = stage === STAGE.WAITING_FOR_COMPLETION || forcePoll;

  const { data: preselectorJobQuery } = usePreselectorJobQuery(undefined, {
    pollingInterval: shouldPoll ? 1500 : 0,
  });

  const [expire] = usePreselectorJobExpirationMutation();

  const currentPreferences = billingQuery?.billing.preferences || [];
  const currentVariationId = billingQuery?.billing.baskets
    .find((b) => b.default)
    ?.basketProducts.find((p) =>
      isMealboxProduct(p.variation.product),
    )?.variationId;

  const currentConsentIds =
    billingQuery?.billing.consents
      .filter((c) => c.isAccepted)
      .map((c) => c.consentId) || [];

  const currentConceptPreferenceIds = currentPreferences
    .filter(isConceptPreference)
    .map((p) => p.preferenceId);

  const currentTastePreferenceIds = currentPreferences
    .filter(isTastePreference)
    .map((p) => p.preferenceId);

  const [generatePersonalizedDeviations] =
    useGeneratePersonalizedDeviationsMutation();

  const [updateBillingAgreement] = useUpdateBillingAgreementMutation();
  const [updatePreferences] = useUpdatePreferencesMutation();
  const [updateUserConsentsMutation] = useUpdateUserConsentsMutation();

  const setStage = (stage: STAGE) => {
    _setStage(stage);

    if (onStageChange) {
      onStageChange[stage]?.();
    }
  };

  useEffect(() => {
    if (!preselectorJobQuery) {
      return;
    }

    switch (preselectorJobQuery.acceptedRequestStatus) {
      case "NOT_FOUND":
      case "NONE":
      case "DROPPED":
        return;
      case "RECEIVED":
      case "RECOMMENDATION_REQUESTED":
        if (stage !== STAGE.WAITING_FOR_COMPLETION) {
          setStage(STAGE.WAITING_FOR_COMPLETION);
        }
        return;
      case "FAILED":
        if (stage !== STAGE.FAILED) {
          setStage(STAGE.FAILED);
        }
        return;
      case "COMPLETED":
        if (stage !== STAGE.COMPLETED) {
          dispatch(api.util.invalidateTags([tags.calendar, tags.billing]));
          setStage(STAGE.COMPLETED);
        }
        return;
      case "COMPLETED_WITH_ALLERGEN":
        if (stage !== STAGE.COMPLETED_WITH_ALLERGEN) {
          dispatch(api.util.invalidateTags([tags.calendar, tags.billing]));
          setStage(STAGE.COMPLETED_WITH_ALLERGEN);
        }
        return;
      default:
        return;
    }
  }, [preselectorJobQuery]);

  const trigger = useCallback(
    async (args: TriggerArgs) => {
      const {
        week,
        year,
        variationId,
        conceptPreferenceIds,
        tastePreferenceIds,
        requiredConsentIds,
        overrideDeviations = false,
      } = args;

      setStage(STAGE.IDLE);

      if (!billingQuery) {
        setStage(STAGE.FAILED);
        return "FAILED";
      }

      const consentDifferences =
        requiredConsentIds &&
        difference(requiredConsentIds, currentConsentIds, isEqualStrings);

      const consentsAreMissing = !consentDifferences?.every(
        (diff) => !requiredConsentIds?.some((c) => isEqualStrings(c, diff)),
      );

      if (consentsAreMissing && requiredConsentIds) {
        setStage(STAGE.UPDATING_CONSENTS);

        await updateUserConsentsMutation(
          requiredConsentIds.map((id) => ({ consentId: id, isAccepted: true })),
        ).unwrap();
      }

      const preferencesHaveChanged =
        isNotEmptyArray(
          difference(
            [...(conceptPreferenceIds || []), ...(tastePreferenceIds || [])],
            [...currentConceptPreferenceIds, ...currentTastePreferenceIds],
            isEqualStrings,
          ),
        ) &&
        (!!conceptPreferenceIds || !!tastePreferenceIds);

      if (preferencesHaveChanged) {
        setStage(STAGE.UPDATING_PREFERENCES);

        const preferencesToUpdate: {
          preferenceTypeId: string;
          activePreferences: string[];
        }[] = [];

        if (conceptPreferenceIds) {
          preferencesToUpdate.push({
            preferenceTypeId: PREFERENCE_TYPE_IDS.CONCEPT,
            activePreferences: conceptPreferenceIds,
          });
        }
        if (tastePreferenceIds) {
          preferencesToUpdate.push({
            preferenceTypeId: PREFERENCE_TYPE_IDS.TASTE,
            activePreferences: tastePreferenceIds,
          });
        }

        await updatePreferences(preferencesToUpdate).unwrap();
      }

      if (isNotEqualStrings(variationId, currentVariationId) && variationId) {
        setStage(STAGE.UPDATING_BILLING_AGREEMENT);

        await updateBillingAgreement({
          _suppressNotification: true,
          ...convertFromBillingToUpdateBillingAgreement({
            ...billingQuery.billing,
            baskets: billingQuery.billing.baskets.map((basket) => {
              if (!basket.default) {
                return basket;
              }

              const addonProducts = basket.basketProducts.filter((bp) => {
                const isMealbox =
                  bp.variation.product.productTypeId ===
                  PRODUCT_TYPE_IDS.MEALBOX;

                return !isMealbox;
              });

              return {
                ...basket,
                basketProducts: [
                  ...addonProducts,
                  {
                    quantity: 1,
                    variationId: variationId,
                  } as any,
                ],
              };
            }),
          }),
        }).unwrap();
      }

      setStage(STAGE.GENERATING_DEVIATIONS);

      await generatePersonalizedDeviations({
        startWeek: week,
        startYear: year,
        range: 5,
        overrideDeviations,
      }).unwrap();

      setStage(STAGE.WAITING_FOR_COMPLETION);

      return "PENDING";
    },
    [
      billingQuery,
      generatePersonalizedDeviations,
      updateBillingAgreement,
      updatePreferences,
    ],
  );

  return { trigger, stage, expire };
};
