import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      CHOOSE_BETWEEN_N_DISHES: (dishCount: number) =>
        `Velg blant ${dishCount} retter!`,
      MORE_DISHES: (dishCount: number) => `+${dishCount} retter til!`,
      SIGN_UP_AND_ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK:
        "Registrer deg, og nyt vårt store utvalg av gode middager hver uke.",
      ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK:
        "Nyt vårt store utvalg av gode middager hver uke.",
      CLOSE: "Lukk",
    },
    se: {
      CHOOSE_BETWEEN_N_DISHES: (dishCount: number) =>
        `Välj bland ${dishCount} rätter!`,
      MORE_DISHES: (dishCount: number) => `+${dishCount} rätter till!`,
      SIGN_UP_AND_ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK:
        "Registrera dig och njut av vårt breda utbud av goda rätter varje vecka.",
      ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK:
        "Njut av vårt breda utbud av älskade och lättlagade rätter varje vecka.",
      CLOSE: "Stäng",
    },
    dk: {
      CHOOSE_BETWEEN_N_DISHES: (dishCount: number) =>
        `Vælg mellem ${dishCount} retter!`,
      MORE_DISHES: (dishCount: number) => `+${dishCount} retter mere!`,
      SIGN_UP_AND_ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK:
        "Tilmeld dig og nyd det store udvalg af lækre opskrifter hver uge.",
      ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK:
        "Nyd det store udvalg af lækre opskrifter hver uge.",
      CLOSE: "Luk",
    },
  } as const
)[language];
