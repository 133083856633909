import { isStandaloneProduct, isAddonProduct } from "@chef/helpers";
import { CalendarQuery } from "@chef/state-management";
import { useCalendarData } from "@chef/state-management/hooks";
import { isEqualStrings } from "@chef/utils/equal";
import { getPriceOfProductByVariation } from "@chef/state-management/helpers";

import { Groceries } from "../shared/Groceries";

interface CalendarWeekGroceriesProps {
  calendarWeek: CalendarQuery["calendar"][number];
  initiallyExpanded?: boolean;
}

export const CalendarWeekGroceries = ({
  calendarWeek,
  initiallyExpanded,
}: CalendarWeekGroceriesProps) => {
  const { basket, productsByCategories } = useCalendarData({
    week: calendarWeek.week,
    year: calendarWeek.year,
  });

  const productsByCategoriesVariations = productsByCategories
    ?.flatMap((c) => c.products)
    ?.flatMap((p) => p.variations);

  const groceries = basket
    .filter((b) => isStandaloneProduct(b) || isAddonProduct(b))
    .map((p) => ({
      variationId: p.variationId,
      quantity: p.quantity,
      variation: productsByCategoriesVariations?.find((pbcv) =>
        isEqualStrings(pbcv.variationId, p.variationId),
      ),
    }));

  return (
    <Groceries
      groceries={groceries.map((grocery) => ({
        name: grocery.variation?.product?.name || "",
        quantity: grocery.quantity,
        price: grocery.variation
          ? getPriceOfProductByVariation(grocery.variation) * grocery.quantity
          : 0,
      }))}
      initiallyExpanded={initiallyExpanded}
    />
  );
};
