import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ORDER_TYPE_IDS, PRODUCT_TYPE_IDS } from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";

import { api } from "../graphql/api";
import {
  CalendarQueryVariables,
  OrdersQueryVariables,
} from "../graphql/generated";
import { addResetEndpointOnLogin } from "./auth";
import {
  addResetEndpointOnUpdateBilling,
  addResetEndpointOnUpdateCalendar,
} from "./billing";
import { addResetEndpointOnDirectOrderUpdates } from "./directOrder";
import type { RootState } from "..";
import { getPriceOfProductByVariation } from "../helpers";

export interface IDeviationProduct {
  quantity: number;
  price: number;
  variationId: string;
  productId: string;
  productTypeId: string;
}

export interface IDeviationBasket {
  week: number;
  year: number;
  isDirty?: boolean;
  products: IDeviationProduct[];
}

export interface IHasSetInitialPriceData {
  week: number;
  year: number;
  set: boolean;
}
export interface ICampaignProduct {
  quantity: number;
  price: number;
  variationId: string;
  productTypeId: string;
  productId: string;
}
export interface ICampaignBasket {
  week: number;
  year: number;
  products: ICampaignProduct[];
}

const initialState = {
  defaultBasketInitialized: false,
  hasSetInitialPriceData: [] as IHasSetInitialPriceData[],
  addonProducts: [] as IDeviationProduct[],

  deviations: [] as IDeviationBasket[],

  currentlySubmittingQuickAddVariationId: null as string | null,

  campaignBaskets: [] as ICampaignBasket[],
  defaultCampaignBasketInitialized: false,
};

// NOTE: Needs to be called before using addonProducts
export const loadDefaultBasketAddonProducts = createAsyncThunk(
  "basket/loadDefaultBasketAddonProducts",
  async (_, { dispatch }) => {
    const { billing } = await dispatch(
      api.endpoints.billing.initiate(),
    ).unwrap();
    if (!billing) {
      return;
    }

    const defaultBasket = billing.baskets.find((b) => b.default);
    if (!defaultBasket) {
      console.error("Unreachable state reached: no default basket found");
      return;
    }
    dispatch(
      basketSlice.actions.addInitialAddonProducts(
        defaultBasket.basketProducts.map((bp) => ({
          productId: bp.variation.productId,
          productTypeId: bp.variation.product.productTypeId,
          variationId: bp.variationId,
          price: bp.variation.finalPrice,
          quantity: bp.quantity,
        })),
      ),
    );
  },
);

// NOTE: needs to be called before using deviation
export const loadCalendar = createAsyncThunk(
  "basket/loadCalendar",
  async (args: CalendarQueryVariables, { dispatch }) => {
    const { calendar } = await dispatch(
      api.endpoints.calendar.initiate(args),
    ).unwrap();

    if (!calendar) {
      return;
    }

    for (const { week, year, baskets } of calendar) {
      const productDeviations = [] as IDeviationBasket["products"];
      for (const {
        basketDetails: { products },
      } of baskets) {
        for (const product of products) {
          productDeviations.push({
            quantity: product.quantity,
            variationId: product.variationId,
            productId: product.variation.productId,
            productTypeId: product.variation.product.productTypeId,
            price: getPriceOfProductByVariation(product.variation),
          });
        }
      }

      dispatch(
        basketSlice.actions.addInitialDeviation({
          week,
          year,
          products: productDeviations,
        }),
      );
    }
  },
);

export const loadCampaignBasket = createAsyncThunk(
  "basket/loadCampaignBasket",
  async (
    args: OrdersQueryVariables & {
      slug: string;
      week: number;
      year: number;
    },
    { dispatch },
  ) => {
    const { slug, week, year, ...rest } = args;

    const ordersQuery = await dispatch(
      api.endpoints.orders.initiate(rest),
    ).unwrap();

    const { productCategories } = await dispatch(
      api.endpoints.productCategories.initiate(),
    ).unwrap();

    const categories = productCategories.filter(
      ({ hasProducts, completeSlug }) =>
        hasProducts && completeSlug.startsWith(slug),
    );

    const categoryIds = categories.map((c) => c.productCategoryId);
    const { productsByCategories } = await dispatch(
      api.endpoints.productsByCategories.initiate({
        categoryIds,
        week,
        year,
      }),
    ).unwrap();

    const orders = ordersQuery?.orders.filter((order) =>
      isEqualStrings(
        order.orderTypeId.toLowerCase(),
        ORDER_TYPE_IDS.CAMPAIGN.toLowerCase(),
      ),
    );

    const baskets: ICampaignBasket[] = [];

    for (const { week, year, orderLines } of orders) {
      const basket = [] as ICampaignBasket["products"];
      const products = productsByCategories.flatMap((pbc) => pbc.products);
      for (const ol of orderLines) {
        const product = products.find((product) =>
          product.variations.find((v) => v.variationId === ol.variationId),
        );
        if (!product) {
          continue;
        }

        basket.push({
          quantity: ol.quantity,
          variationId: ol.variationId,
          price: +ol.pricePerQuantity,
          productTypeId: product.productTypeId,
          productId: product.productId,
        });
      }

      baskets.push({
        week,
        year,
        products: basket,
      });
    }

    dispatch(basketSlice.actions.addInitialCampaignBaskets(baskets));
  },
);

export const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    // default/addon basket
    addInitialAddonProducts: (
      state,
      { payload }: PayloadAction<IDeviationProduct[]>,
    ) => {
      if (!state.defaultBasketInitialized) {
        state.addonProducts = payload;

        state.defaultBasketInitialized = true;
      }
    },

    setAddonProducts: (
      state,
      { payload }: PayloadAction<IDeviationProduct[]>,
    ) => {
      state.addonProducts = payload;
    },

    resetDefaultAddonProducts: (state) => ({
      ...state,
      defaultBasketInitialized: false,
      addonProducts: [],
    }),

    // initial price data
    setHasSetInitialPrice: (
      state,
      { payload }: PayloadAction<IHasSetInitialPriceData>,
    ) => {
      const data = state.hasSetInitialPriceData.find(
        (d) => d.week === payload.week && d.year === payload.year,
      );
      if (!data) {
        // shouldn't be possible to happen in theory
        state.hasSetInitialPriceData.push(payload);
        return;
      }

      data.set = payload.set;
    },

    // deviation/calendar
    addInitialDeviation: (
      state,
      { payload }: PayloadAction<Omit<IDeviationBasket, "isDirty">>,
    ) => {
      const { week, year } = payload;

      const idx = state.deviations.findIndex(
        (d) => d.week === week && d.year === year,
      );
      // we already have it
      if (idx !== -1) {
        return;
      }

      state.deviations.push({ ...payload, isDirty: false });
      state.hasSetInitialPriceData.push({ week, year, set: false });
    },
    setDeviation: (
      state,
      {
        payload,
      }: PayloadAction<{
        basket: Omit<IDeviationBasket, "isDirty">;
        manual?: boolean;
      }>,
    ) => {
      const idx = state.deviations.findIndex(
        (d) => d.week === payload.basket.week && d.year === payload.basket.year,
      );
      if (idx === -1) {
        state.deviations.push({
          ...payload.basket,
          isDirty: payload.manual ? true : false,
        });
      } else {
        state.deviations[idx] = {
          ...payload.basket,
          isDirty: payload.manual ? true : state.deviations[idx].isDirty,
        };
      }
    },
    addProductToDeviation: (
      state,
      {
        payload,
      }: PayloadAction<{
        week: number;
        year: number;
        product: IDeviationProduct;
      }>,
    ) => {
      const idx = state.deviations.findIndex(
        (d) => d.week === payload.week && d.year === payload.year,
      );

      if (idx !== -1) {
        state.deviations[idx].isDirty = true;
        const productIdx = state.deviations[idx].products.findIndex(
          (p) => p.productId === payload.product.productId,
        );
        if (productIdx === -1) {
          state.deviations[idx].products.push(payload.product);
        }
      }
    },
    removeProductFromDeviation: (
      state,
      {
        payload,
      }: PayloadAction<{
        week: number;
        year: number;
        product: IDeviationProduct;
      }>,
    ) => {
      const idx = state.deviations.findIndex(
        (d) => d.week === payload.week && d.year === payload.year,
      );

      if (idx !== -1) {
        state.deviations[idx].isDirty = true;
        const productIdx = state.deviations[idx].products.findIndex(
          (p) => p.productId === payload.product.productId,
        );

        if (productIdx !== -1) {
          state.deviations[idx].products.splice(productIdx, 1);
        }
      }
    },
    updateAddonToDeviation: (
      state,
      {
        payload,
      }: PayloadAction<{
        week: number;
        year: number;
        product: IDeviationBasket["products"][0];
      }>,
    ) => {
      const deviation = state.deviations.find(
        (d) => d.week === payload.week && d.year === payload.year,
      );
      if (!deviation) {
        return;
      }
      deviation.isDirty = true;
      const { productId, variationId } = payload.product;

      const idx = deviation.products.findIndex(
        (p) => p.productId === productId && p.variationId === variationId,
      );
      if (idx === -1 && payload.product.quantity > 0) {
        deviation.products.push(payload.product);
        return;
      }

      if (payload.product.quantity === 0) {
        deviation.products.splice(idx, 1);
        return;
      }

      deviation.products[idx] = payload.product;
    },

    setCurrentlySubmittingQuickAddVariationId: (
      state,
      action: PayloadAction<{ variationId: string | null }>,
    ) => ({
      ...state,
      currentlySubmittingQuickAddVariationId: action.payload.variationId,
    }),

    resetDeviations: (state) => ({
      ...state,
      deviations: [],
      hasSetInitialPriceData: [],
    }),

    // CampaignBasket
    addInitialCampaignBaskets: (
      state,
      { payload }: PayloadAction<ICampaignBasket[]>,
    ) => {
      if (!state.campaignBaskets) {
        state.campaignBaskets = [];
      }

      for (const basket of payload) {
        const { week, year } = basket;
        const idx = state.campaignBaskets.findIndex(
          (d) => d.week === week && d.year === year,
        );

        // we already have it
        if (idx !== -1) {
          continue;
        }

        state.campaignBaskets.push(basket);
      }

      state.defaultCampaignBasketInitialized = true;
    },

    addProductToCampaignBasket: (
      state,
      {
        payload,
      }: PayloadAction<{
        product: Omit<ICampaignProduct, "quantity">;
        week: number;
        year: number;
      }>,
    ) => {
      const {
        week,
        year,
        product: { productId, variationId, price, productTypeId },
      } = payload;

      const idx = state.campaignBaskets.findIndex(
        (d) => d.week === week && d.year === year,
      );
      if (idx === -1) {
        state.campaignBaskets.push({
          week: week,
          year: year,
          products: [
            { productId, variationId, price, productTypeId, quantity: 1 },
          ],
        });
      } else {
        const basket = state.campaignBaskets[idx];

        const productIdx = basket.products.findIndex(
          (p) => p.productId === productId && p.variationId === variationId,
        );

        if (productIdx === -1) {
          basket.products.push({
            productId,
            variationId,
            price,
            productTypeId,
            quantity: 1,
          });
        } else {
          basket.products[productIdx].quantity++;
        }
      }
    },

    removeProductFromCampaignBasket: (
      state,
      {
        payload,
      }: PayloadAction<{
        product: Pick<ICampaignProduct, "variationId">;
        week: number;
        year: number;
      }>,
    ) => {
      const {
        week,
        year,
        product: { variationId },
      } = payload;

      const basket = state.campaignBaskets.find(
        (d) => d.week === week && d.year === year,
      );

      if (!basket) {
        return;
      }

      const productIdx = basket.products.findIndex(
        (p) => p.variationId === variationId,
      );

      if (productIdx === -1) {
        return;
      }

      basket.products[productIdx].quantity--;

      if (basket.products[productIdx].quantity === 0) {
        basket.products.splice(productIdx, 1);
      }
    },

    updateGroceryProductFromCampaignBasket: (
      state,
      {
        payload,
      }: PayloadAction<ICampaignProduct & { week: number; year: number }>,
    ) => {
      const {
        variationId,
        price,
        productId,
        productTypeId,
        quantity,
        week,
        year,
      } = payload;

      const idx = state.campaignBaskets.findIndex(
        (d) => d.week === week && d.year === year,
      );

      if (idx === -1) {
        return;
      }
      const basket = state.campaignBaskets[idx];

      const productIdx = basket.products.findIndex(
        (p) => p.variationId === variationId,
      );

      if (productIdx === -1) {
        basket.products.push({
          productId,
          variationId,
          price,
          productTypeId,
          quantity: 1,
        });
      } else if (quantity === 0) {
        basket.products.splice(productIdx, 1);
      } else {
        basket.products[productIdx].quantity = quantity;
      }
    },

    resetCampaignBaskets: (state) => ({
      ...state,
      campaignBaskets: [],
      defaultCampaignBasketInitialized: false,
    }),
  },
});

// this will handle resetting the state when the user changes billing
addResetEndpointOnUpdateBilling(basketSlice.actions.resetDefaultAddonProducts);

// this will handle resetting the state when the user changes calendar
addResetEndpointOnUpdateCalendar(basketSlice.actions.resetDeviations);

// this will handle resetting the state when the user submits a direct order
addResetEndpointOnDirectOrderUpdates(basketSlice.actions.resetCampaignBaskets);

// this will handle resetting the state when user logs in
addResetEndpointOnLogin(basketSlice.actions.resetDeviations);
addResetEndpointOnLogin(basketSlice.actions.resetCampaignBaskets);

export const {
  setAddonProducts,
  setDeviation,
  addProductToDeviation,
  removeProductFromDeviation,
  setHasSetInitialPrice,
  updateAddonToDeviation,
  resetDeviations,
  addProductToCampaignBasket,
  removeProductFromCampaignBasket,
  updateGroceryProductFromCampaignBasket,
  setCurrentlySubmittingQuickAddVariationId,
} = basketSlice.actions;

export const selectAddonProducts = (state: RootState) =>
  state.basket.addonProducts.filter((product) =>
    (
      [
        PRODUCT_TYPE_IDS.STANDALONE,
        PRODUCT_TYPE_IDS.ADDON,
        PRODUCT_TYPE_IDS.LIMITED_QUANTITY,
      ] as string[]
    ).includes(product.productTypeId),
  );

export const selectDeviationBasket =
  ({ week, year }: { week: number; year: number }) =>
  (state: RootState) =>
    state.basket.deviations.find((d) => d.week === week && d.year === year);

/**
 * NOTE: this requires useCreateDeviationBasket to be ran
 */
export const selectDeviationProducts =
  ({ week, year }: { week: number; year: number }) =>
  (state: RootState) =>
    state.basket.deviations.find((d) => d.week === week && d.year === year)
      ?.products;

export const selectHasSetInitialPrice =
  ({ week, year }: { week: number; year: number }) =>
  (state: RootState) =>
    state.basket.hasSetInitialPriceData.find(
      (d) => d.week === week && d.year === year,
    )?.set || false;

export const selectCampaignBaskets =
  ({ week, year }: { week: number; year: number }) =>
  (state: RootState) =>
    state.basket.campaignBaskets?.find(
      (d) => d.week === +week && d.year === +year,
    );

export const selectIsCampaignBasketInitialized = (state: RootState) =>
  state.basket.defaultCampaignBasketInitialized;

export const selectIsDirtyBasket =
  ({ week, year }: { week: number; year: number }) =>
  (state: RootState) =>
    state.basket.deviations.find(
      (deviation) => deviation.week === week && deviation.year === year,
    )?.isDirty || false;

export const selectCurrentlySubmittingQuickAddVariationId = (
  state: RootState,
) => state.basket.currentlySubmittingQuickAddVariationId;
