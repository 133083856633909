import { isWithinInterval } from "date-fns";

import {
  BASE_64_1X1_GREY,
  BRAND_NAME,
  ProductCategoryId,
  PRODUCT_CATEGORY_IDS,
  PRODUCT_TYPE_IDS,
} from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";
import { parseTimeStringFromApi } from "@chef/utils/helpers/time";
import { removeDuplicates } from "@chef/utils/helpers/removeDuplicates";
import { isSingelPreference } from "@chef/helpers";

import { IDeviationBasket } from "../features";
import {
  Attribute,
  PickAndMixQuery,
  PreselectedProductsForWeekQuery,
  ProductsByCategoriesQuery,
  ProductCategoriesQuery,
  CalendarQuery,
  OrderQuery,
  BillingQuery,
} from "../graphql/generated";
import { getPreselectedMealboxIdFromBasket } from "./basket";

export const getAttribute = (
  name: string,
  obj: { attributes: Attribute[] },
) => {
  return obj.attributes.find((a) => isEqualStrings(a.name, name))?.value;
};

export const getPortionsValueFromVariationWithPlus = (obj: {
  attributes: Attribute[];
}) => {
  const portions = getAttribute("Portions", obj);
  if (!portions) {
    return null;
  }

  return +portions;
};

export const getPortionsValueFromVariation = (obj: {
  attributes: Attribute[];
}) => {
  const portions = getAttribute("Portions", obj);
  if (!portions) {
    return null;
  }

  return +portions[0];
};

export const getMealsValueFromVariation = (obj: {
  attributes: Attribute[];
}) => {
  const meals = getAttribute("Meals", obj);

  if (!meals) {
    return null;
  }

  return +meals;
};

export const isVariationPlusPortions = (obj: { attributes: Attribute[] }) => {
  const portions = getPortionsValueFromVariationWithPlus(obj);
  if (!portions) {
    return false;
  }

  // if value is bigger than 10, it's plus protein
  return portions > 10;
};

export const getPortionsValueAsStringWithPlus = (obj: {
  attributes: Attribute[];
}) => {
  const portions = getPortionsValueFromVariationWithPlus(obj);
  if (!portions) {
    return null;
  }

  // if it's a plus protein portion
  if (portions > 10) {
    return `${portions.toString()[0]}+`;
  }

  return portions.toString();
};

export const findVariationByMealsAndPortions = <
  T extends { attributes: Attribute[] },
>(args: {
  variations: T[];
  portions: number;
  meals: number;
}) => {
  const { variations, portions, meals } = args;

  return variations.find((v) => {
    const portionOption = +(getAttribute("Portions", v) || 0);
    const mealOption = +(getAttribute("Meals", v) || 0);

    return portionOption === portions && meals === mealOption;
  }) as
    | ProductsByCategoriesQuery["productsByCategories"][number]["products"][number]["variations"][number]
    | undefined;
};

export const getPortionsFromVariations = (args: {
  variations?: { attributes: Attribute[] }[];
  skipSorting?: boolean;
}) => {
  const { variations, skipSorting } = args;

  const allPortions: number[] = [];

  if (!variations) {
    return allPortions;
  }

  for (const variation of variations) {
    const portion = +(getAttribute("Portions", variation) || 0);
    if (!portion || isNaN(portion)) {
      continue;
    }

    allPortions.push(portion);
  }

  const uniquePortions = removeDuplicates(allPortions);

  if (!skipSorting) {
    uniquePortions.sort();
  }

  return uniquePortions;
};

export const getMealsFromVariations = (args: {
  variations?: { attributes: Attribute[] }[];
  portions?: number;
  skipSorting?: boolean;
}) => {
  const { variations, portions, skipSorting } = args;
  const allMeals: number[] = [];

  if (!variations) {
    return allMeals;
  }

  for (const variation of variations) {
    const meal = +(getAttribute("Meals", variation) || 0);
    const portion = +(getAttribute("Portions", variation) || 0);

    if (portions && portion !== portions) {
      continue;
    }

    if (!meal || isNaN(meal)) {
      continue;
    }

    allMeals.push(meal);
  }

  const uniqueMeals = removeDuplicates(allMeals);

  if (!skipSorting) {
    uniqueMeals.sort();
  }

  return uniqueMeals;
};

type Image = { isFeatured?: boolean; urls: { size: string; url: string }[] };

export const getImageFromImages = (args: {
  images: Image[] | Image;
  withoutFallback?: boolean;
  isFeatured?: boolean;
  size?: string;
}) => {
  const { images, withoutFallback, isFeatured, size } = args;

  if (images instanceof Array) {
    for (const image of images) {
      if (isFeatured && !image.isFeatured) {
        continue;
      }

      for (const url of image.urls) {
        if (size && !isEqualStrings(url.size, size)) {
          continue;
        }

        return url.url;
      }
    }

    if (withoutFallback) {
      return "";
    }

    // if the caller passed size and want a fallback, check the first element and return it
    if (size && images[0]) {
      return images[0]?.urls?.[0]?.url;
    }
  } else {
    if (images) {
      for (const url of images.urls) {
        if (size && !isEqualStrings(url.size, size)) {
          continue;
        }

        return url.url;
      }
    }
  }

  if (withoutFallback) {
    return "";
  }

  return BASE_64_1X1_GREY;
};

type VariationType =
  | PickAndMixQuery["pickAndMix"][number]["product"]["variations"][number]
  | OrderQuery["order"]["orderLines"][number]["variation"];

export const getImageFromVariation = (
  args: {
    variation: VariationType;
  } & Omit<Parameters<typeof getImageFromImages>[0], "images">,
) => {
  const { variation, ...rest } = args;

  return getImageFromImages({ ...rest, images: variation.images });
};

export const isProductPreselected = (args: {
  basketProducts: IDeviationBasket["products"];
  productId: string;
  productsByCategories: ProductsByCategoriesQuery["productsByCategories"];
  preselectedProductsForWeek: PreselectedProductsForWeekQuery["preselectedProductsForWeek"];
  week: number;
  year: number;
}) => {
  const {
    basketProducts,
    productId,
    productsByCategories,
    preselectedProductsForWeek,
    week,
    year,
  } = args;

  const preselectedMealboxId = getPreselectedMealboxIdFromBasket({
    basketProducts,
    productsByCategories,
  });
  if (!preselectedMealboxId) {
    return false;
  }

  const preselected = preselectedProductsForWeek.find((pp) =>
    isEqualStrings(pp.productId, preselectedMealboxId),
  );
  if (!preselected) {
    return false;
  }

  return preselected.preselectedProducts.some(
    (pp) =>
      isEqualStrings(pp.productId, productId) &&
      pp.week === week &&
      pp.year === year,
  );
};

/**
 * @param variation
 * @returns the price of the product, if it's on campaign it will return the discounted price
 */
export const getPriceOfProductByVariation = (
  variation:
    | PickAndMixQuery["pickAndMix"][number]["product"]["variations"][number]
    | ProductsByCategoriesQuery["productsByCategories"][number]["products"][number]["variations"][number]
    | CalendarQuery["calendar"][number]["baskets"][number]["basketDetails"]["products"][number]["variation"],
) => {
  const { campaignDiscount, finalPrice } = variation.flexiblePriceV2;

  const price = campaignDiscount || finalPrice;

  return price;
};

/**
 * @param variation
 * @returns the amount of discount on the product. If there is no discount, it will return 0
 * @example The product costs 150kr, but is on sale for 100kr. The discount is 50kr, and the function will return 50
 */
export const getDiscountedAmount = (
  variation:
    | PickAndMixQuery["pickAndMix"][number]["product"]["variations"][number]
    | ProductsByCategoriesQuery["productsByCategories"][number]["products"][number]["variations"][number]
    | CalendarQuery["calendar"][number]["baskets"][number]["basketDetails"]["products"][number]["variation"],
): number => {
  // In some scenarios, we don't have week/year.
  // In those cases, flexiblePriceRange _should_ have a range of 1 so we can default to the first element
  const { campaignDiscount, finalPrice } = variation.flexiblePriceV2;

  if (!campaignDiscount) {
    return 0;
  }

  if (+campaignDiscount > finalPrice) {
    return 0;
  }

  return finalPrice - campaignDiscount;
};

export const getPriceOfProduct = (args: {
  variationId: string;
  product: PickAndMixQuery["pickAndMix"][number]["product"];
  isPreselected?: boolean;
}) => {
  const { variationId, product, isPreselected } = args;

  const variation = product.variations.find((v) =>
    isEqualStrings(v.variationId, variationId),
  );
  if (!variation) {
    throw new Error(
      `Failed to find price for ${variationId} - ${product.productId}`,
    );
  }

  // if it isn't preselected, then the price logic is straight forward
  if (!isPreselected) {
    return getPriceOfProductByVariation(variation);
  }

  // if it isn't a plus portion and it's preselected, then the price is 0
  if (!isVariationPlusPortions(variation)) {
    return 0;
  }

  // find non plus portion variation
  const portion = getPortionsValueFromVariation(variation);
  for (const v of product.variations) {
    if (isEqualStrings(v.variationId, variationId)) {
      continue;
    }
    if (portion !== getPortionsValueFromVariation(v)) {
      continue;
    }

    const normalPrice = getPriceOfProductByVariation(v);
    const plusPortionPrice = getPriceOfProductByVariation(variation);
    return plusPortionPrice - normalPrice;
  }

  throw new Error(
    `Failed to find matching non plus portion variation: ${variationId} - ${product.productId}`,
  );
};

// TODO: Onesub migration: need to take Preferences into consideration, because of Singel edge case
export const getPortionsAvailableFromProductsByCategories = (args: {
  productId: string;
  productCategoryId: ProductCategoryId;
  productsByCategories: ProductsByCategoriesQuery["productsByCategories"];
  preferences?: BillingQuery["billing"]["preferences"];
}) => {
  const { productId, productCategoryId, productsByCategories } = args;

  const pbc = productsByCategories.find((pbc) =>
    isEqualStrings(pbc.productCategoryId, productCategoryId),
  );
  if (!pbc) {
    return [];
  }

  const portions: number[] = [];

  for (const product of pbc.products) {
    if (product.productId !== productId) {
      continue;
    }

    for (const variation of product.variations) {
      const portion = getAttribute("Portions", variation);
      if (!portion || portions.includes(+portion)) {
        continue;
      }
      if (
        !args.preferences?.find((p) => isSingelPreference(p)) &&
        +portion === 1
      ) {
        continue;
      }

      portions.push(+portion);
    }
  }

  return portions;
};

// TODO: Onesub migration: added to check available dishCount for mealbox, standard will most likely be 2
export const getDishCountAvailableFromProductsByCategories = (args: {
  productId: string;
  productCategoryId: ProductCategoryId;
  productsByCategories: ProductsByCategoriesQuery["productsByCategories"];
}) => {
  const { productId, productsByCategories, productCategoryId } = args;

  const pbc = productsByCategories.find((pbc) =>
    isEqualStrings(pbc.productCategoryId, productCategoryId),
  );
  if (!pbc) {
    return [];
  }

  const dishCounts: number[] = [];

  for (const product of pbc.products) {
    if (product.productId !== productId) {
      continue;
    }

    for (const variation of product.variations) {
      const dishCount = getAttribute("Meals", variation);
      if (!dishCount || dishCounts.includes(+dishCount)) {
        continue;
      }

      dishCounts.push(+dishCount);
    }
  }

  return dishCounts;
};

export const getVariationFromProductsByCategories = (args: {
  variationId: string;
  productCategoryId?: ProductCategoryId;
  productId?: string;
  productsByCategories: ProductsByCategoriesQuery["productsByCategories"];
}) => {
  const { variationId, productCategoryId, productsByCategories, productId } =
    args;

  for (const pbc of productsByCategories) {
    // if it's not the category we want
    if (
      productCategoryId &&
      !isEqualStrings(pbc.productCategoryId, productCategoryId)
    ) {
      continue;
    }

    for (const product of pbc.products) {
      if (productId && !isEqualStrings(product.productId, productId)) {
        continue;
      }

      for (const v of product.variations) {
        if (!isEqualStrings(v.variationId, variationId)) {
          continue;
        }

        return v;
      }
    }
  }

  return null;
};

export const getVariationFromPickAndMix = (args: {
  pickAndMix: PickAndMixQuery["pickAndMix"];
  productId: string;
  variationId: string;
}) => {
  const { pickAndMix, productId, variationId } = args;

  for (const product of pickAndMix) {
    if (!isEqualStrings(product.productId, productId)) {
      continue;
    }

    for (const variation of product.product.variations) {
      if (!isEqualStrings(variation.variationId, variationId)) {
        continue;
      }

      return variation;
    }
  }

  return null;
};

export const getVariationFromPickAndMixProductWithPortions = (args: {
  pickAndMixProduct: PickAndMixQuery["pickAndMix"][number]["product"];
  portions: number;
}) => {
  const { pickAndMixProduct, portions } = args;

  for (const variation of pickAndMixProduct.variations) {
    const p = getAttribute("Portions", variation);
    if (!p || +p !== portions) {
      continue;
    }

    return variation;
  }

  return null;
};

export const getMealboxVariationFromFinancialVariation = (args: {
  financialVariation:
    | PickAndMixQuery["pickAndMix"][number]["product"]["variations"][number]
    | CalendarQuery["calendar"][number]["baskets"][number]["basketDetails"]["products"][number]["variation"];
  productsByCategories: ProductsByCategoriesQuery["productsByCategories"];
}) => {
  const { financialVariation, productsByCategories } = args;

  const mealboxProductId = getAttribute(
    "preselected_for_mealbox_product_id",
    financialVariation,
  );
  if (!mealboxProductId) {
    return null;
  }

  for (const pbc of productsByCategories) {
    if (
      ![
        PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_IN,
        PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_OUT,
        // can't compare string with a const string; therefor making it any
      ].includes(pbc.productCategoryId as any)
    ) {
      continue;
    }

    for (const product of pbc.products) {
      if (!isEqualStrings(product.productId, mealboxProductId)) {
        continue;
      }

      for (const variation of product.variations) {
        const financialVariationId = getAttribute(
          "flex_financial_variation_id",
          variation,
        );
        if (
          !isEqualStrings(financialVariation.variationId, financialVariationId)
        ) {
          continue;
        }

        return variation;
      }
    }
  }

  return null;
};

const shouldShowProductBySalesDate = (args: {
  attributes: Attribute[];
  cutoffDate: Date;
}) => {
  const startDateStr = getAttribute("start_sales_date", args);
  const endDateStr = getAttribute("end_sales_date", args);
  if (!startDateStr || !endDateStr) {
    return true;
  }

  const startDate = parseTimeStringFromApi(startDateStr);
  const endDate = parseTimeStringFromApi(endDateStr);

  return isWithinInterval(args.cutoffDate, {
    start: startDate,
    end: endDate,
  });
};

export const filterPickAndMix = (args: {
  pickAndMix: PickAndMixQuery["pickAndMix"];
  cutoffDate?: Date;
  // portions without plus
  portions?: number;
}) => {
  const { cutoffDate, portions } = args;
  const pickAndMix: PickAndMixQuery["pickAndMix"] = [];

  for (const product of args.pickAndMix) {
    const variations = product.product.variations.filter((variation) => {
      const { attributes } = variation;

      if (
        cutoffDate &&
        !shouldShowProductBySalesDate({ attributes, cutoffDate })
      ) {
        return false;
      }

      if (portions && getPortionsValueFromVariation(variation) !== portions) {
        return false;
      }

      return true;
    });

    const recipes = product.recipes.filter((recipe) => {
      if (portions && !recipe.portions.some((item) => item.size === portions)) {
        return false;
      }

      return true;
    });

    if (variations.length === 0 || recipes.length === 0) {
      continue;
    }

    pickAndMix.push({
      ...product,
      product: {
        ...product.product,
        variations,
      },
    });
  }

  return pickAndMix;
};

export const getSortedProductsByCategories = (args: {
  productsByCategories: ProductsByCategoriesQuery["productsByCategories"];
  productCategories: ProductCategoriesQuery["productCategories"];
}) => {
  const { productsByCategories, productCategories } = args;

  return [...productsByCategories].sort((a, b) => {
    const aSortingValue = productCategories.find(
      (pc) => pc.productCategoryId === a.productCategoryId,
    )?.sortingValue;

    const bSortingValue = productCategories.find(
      (pc) => pc.productCategoryId === b.productCategoryId,
    )?.sortingValue;

    if (aSortingValue === undefined || bSortingValue === undefined) {
      return 0;
    }

    return aSortingValue - bSortingValue;
  });
};

export const filterAndSortProductsByCategoriesWithDate = (args: {
  productsByCategories: ProductsByCategoriesQuery["productsByCategories"];
  productCategories: ProductCategoriesQuery["productCategories"];
  cutoffDate: Date;
  shouldCheckIfSubscribable?: boolean;
}) => {
  const {
    cutoffDate,
    productsByCategories,
    productCategories,
    shouldCheckIfSubscribable = false,
  } = args;
  const ret: ProductsByCategoriesQuery["productsByCategories"] = [];

  const sortedProductsByCategories = getSortedProductsByCategories({
    productsByCategories,
    productCategories,
  });

  for (const pbc of sortedProductsByCategories) {
    const products: ProductsByCategoriesQuery["productsByCategories"][number]["products"] =
      [];

    for (const product of pbc.products) {
      const variations = product.variations.filter(({ attributes }) => {
        let isVariationAllowedToSubscribe = true;

        if (shouldCheckIfSubscribable) {
          const variation = product.variations?.find((v) => v);

          if (!variation) {
            return null;
          }

          isVariationAllowedToSubscribe =
            getAttribute("isAllowedToSubscribe", variation) === "True";
        }

        return (
          shouldShowProductBySalesDate({ attributes, cutoffDate }) &&
          isVariationAllowedToSubscribe
        );
      });

      if (variations.length === 0) {
        continue;
      }

      products.push({ ...product, variations });
    }
    if (products.length === 0) {
      continue;
    }

    ret.push({ ...pbc, products });
  }

  return ret;
};

const slugFilterPath = {
  AMK: "app/min-matkasse/uke/dagligvarer/",
  GL: "app/min-matkasse/uke/frokost/",
  LMK: "mina-sidor/min-meny/vecka/tillagg/",
  RN: "app/min-matkasse/uke/dagligvarer/",
}[BRAND_NAME];
export const getStandaloneProductCategoryIdsFromProductCategories = (
  productCategories: ProductCategoriesQuery["productCategories"],
) => {
  return productCategories
    .filter(
      (pc) => pc.hasProducts && pc.completeSlug.startsWith(slugFilterPath),
    )
    .map((pc) => pc.productCategoryId);
};

export const convertFromPortionsToPlusPortions = (portions: number) => {
  return +`${portions}1`;
};

interface IIsSomethingProduct {
  productTypeId: string;
}

export const isPickAndMixProduct = (product: IIsSomethingProduct) => {
  return isEqualStrings(product.productTypeId, PRODUCT_TYPE_IDS.PICKANDMIX);
};

export const isMealboxProduct = (product: IIsSomethingProduct) => {
  return isEqualStrings(product.productTypeId, PRODUCT_TYPE_IDS.MEALBOX);
};

export const isFinancialProduct = (product: IIsSomethingProduct) => {
  return isEqualStrings(product.productTypeId, PRODUCT_TYPE_IDS.FINANCIAL);
};

export const isAddonProduct = (product: IIsSomethingProduct) => {
  return isEqualStrings(product.productTypeId, PRODUCT_TYPE_IDS.ADDON);
};

export const isStandaloneProduct = (product: IIsSomethingProduct) => {
  return isEqualStrings(product.productTypeId, PRODUCT_TYPE_IDS.STANDALONE);
};

export const isLimitedQuantityProduct = (product: IIsSomethingProduct) => {
  return isEqualStrings(
    product.productTypeId,
    PRODUCT_TYPE_IDS.LIMITED_QUANTITY,
  );
};

export const isAdministrativeProduct = (product: IIsSomethingProduct) => {
  return isEqualStrings(product.productTypeId, PRODUCT_TYPE_IDS.ADMINISTRATIVE);
};

export const getPreselectedProductsByMealboxProductId = (args: {
  productId: string;
  preselectedProductsForWeek: PreselectedProductsForWeekQuery["preselectedProductsForWeek"];
  meals?: number;
  week: number;
  year: number;
}) => {
  const { productId, preselectedProductsForWeek, meals, week, year } = args;

  const preselected = preselectedProductsForWeek.find((ppfw) =>
    isEqualStrings(ppfw.productId, productId),
  );
  if (!preselected) {
    return null;
  }

  const preselectedForWeek = preselected.preselectedProducts.filter(
    (ppfw) => ppfw.week === week && ppfw.year === year,
  );
  if (preselectedForWeek.length === 0) {
    return null;
  }

  if (meals !== undefined) {
    return preselectedForWeek.slice(0, meals);
  }

  return preselectedForWeek;
};

export const getRandomProductsOnSale = ({
  products,
  limit,
}: {
  products: ProductsByCategoriesQuery["productsByCategories"][number]["products"];
  limit: number;
}) => {
  const allProductVariations = products
    .flatMap((product) => product.variations)
    .sort(() => Math.random() - Math.random());

  const variationsOnSale = allProductVariations.filter((variation) => {
    const discountedAmount = getDiscountedAmount(variation);

    return discountedAmount > 0;
  });

  const randomProducts: {
    product: ProductsByCategoriesQuery["productsByCategories"][number]["products"][number];
    variationId: string;
  }[] = [];

  for (const variation of variationsOnSale) {
    if (randomProducts.length >= limit) {
      break;
    }

    const productForVariation = products.find(
      (product) => product.productId === variation.product.productId,
    );

    if (productForVariation) {
      randomProducts.push({
        product: productForVariation,
        variationId: variation.variationId,
      });
    }
  }

  return {
    randomProducts,
  };
};

export const productAndVariationToTrackingData = ({
  selectedProduct,
  selectedVariation,
  quantity = 1,
}: {
  selectedProduct: Required<
    | BillingQuery["billing"]["baskets"][number]["basketProducts"][number]["variation"]["product"]
    | ProductsByCategoriesQuery["productsByCategories"][number]["products"][number]
  >;
  selectedVariation: Required<
    | BillingQuery["billing"]["baskets"][number]["basketProducts"][number]["variation"]
    | ProductsByCategoriesQuery["productsByCategories"][number]["products"][number]["variations"][number]
  >;
  quantity?: number;
}) => {
  const portions = +(getAttribute("Portions", selectedVariation) || 0);
  const meals = +(getAttribute("Meals", selectedVariation) || 0);

  return {
    name: selectedProduct.name,
    portions,
    meals,
    variant: selectedVariation.name,
    variant_id: selectedVariation.variationId,
    category: selectedProduct.productTypeName,
    category_id: selectedProduct.productTypeId,
    price: selectedVariation.finalPrice,
    product_id: selectedProduct.productId,
    quantity,
    sku: selectedVariation.sku,
    url: typeof window !== "undefined" ? window.location.href : undefined,
  };
};
