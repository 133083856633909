import clsx from "clsx";

import { useDispatch } from "react-redux";

import { AnimatedHeart } from "@chef/components";
import { useFavorites } from "@chef/state-management/hooks";
import { setRecipeModal, showFavoriteModal } from "@chef/state-management";
import { useTrack } from "@chef/feature-tracking";

interface FavoriteButtonProps {
  recipe: {
    recipeId: number;
    mainRecipeId: number | null;
    recipeName: string;
  };
  className?: string;
}

export const FavoriteButton = ({ recipe, className }: FavoriteButtonProps) => {
  const { isFavorited, toggleFavorite, enableFavorites } = useFavorites();

  const dispatch = useDispatch();
  const track = useTrack();

  const handleFavoriteAction = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!enableFavorites) {
      dispatch(
        setRecipeModal({
          open: false,
          recipeId: recipe.recipeId,
        }),
      );

      track("buttonClicked", {
        affiliation: "Frontend process",
        button_colour: "outlined",
        button_placement: "favorite-recipe-button",
        button_source: "code",
        button_text: `Favorite button clicked for recipe ${
          recipe.mainRecipeId || recipe.recipeId
        }`,
      });

      dispatch(showFavoriteModal());

      return;
    }

    toggleFavorite(recipe);
  };

  return (
    <button
      onClick={handleFavoriteAction}
      className={clsx(
        "flex items-center justify-center w-10 h-10 bg-white rounded-full hover:bg-white",
        className,
      )}
    >
      <AnimatedHeart
        id={recipe.recipeId.toString()}
        isFilled={isFavorited(recipe)}
        className="w-6 h-6 text-error mt-0.5"
      />
    </button>
  );
};
