import { BRAND_NAME } from "@chef/constants";

export const brand = (
  {
    AMK: {
      ENJOY_THE_FOOD: "Vel bekomme!",
    },
    GL: {
      ENJOY_THE_FOOD: "God middag!",
    },
    LMK: {
      ENJOY_THE_FOOD: "Smaklig måltid!",
    },
    RN: {
      ENJOY_THE_FOOD: "Håber maden smager!",
    },
  } as const
)[BRAND_NAME];
