import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";

import { isEmptyArray } from "@chef/utils/array";
import { Button, Card } from "@chef/components";
import {
  resetPreselectedRecipesModal,
  selectPreselectedRecipesModal,
  useMeQuery,
  usePreferencesQuery,
} from "@chef/state-management";
import { isEqualStrings } from "@chef/utils/equal";
import { getDishCount, getPreviewRecipes } from "@chef/constants";
import { stripEmojis } from "@chef/utils/string";
import { removeDuplicatesByKey } from "@chef/utils/helpers/removeDuplicates";

import { Modal } from "../modal/Modal";
import { PreselectedRecipesList } from "./PreselectedRecipesList";

import { intl } from "./PreselectedRecipesModal.Intl";

interface PreselectedRecipesModalProps {
  portions: number;
  week: number;
  year: number;
}

export const PreselectedRecipesModal = ({
  portions,
  week,
  year,
}: PreselectedRecipesModalProps) => {
  const dispatch = useDispatch();
  const { open, preferenceIds } = useSelector(selectPreselectedRecipesModal);

  const { data: user } = useMeQuery();
  const isLoggedIn = !!user;

  const onClose = () => {
    dispatch(resetPreselectedRecipesModal());
  };

  const { data: preferencesQuery } = usePreferencesQuery();

  if (!preferenceIds || isEmptyArray(preferenceIds)) {
    return null;
  }

  // We need to allow multiple preference Ids to be selected and display the recipes for those selected preferenceIds in the sign-up meal box summary.
  const recipes = removeDuplicatesByKey(
    preferenceIds.flatMap((preferenceId) =>
      getPreviewRecipes({ week, year, preferenceId }),
    ),
    "recipeId",
  );

  const selectedPreference = preferencesQuery?.preferences.find((preference) =>
    preferenceIds.some((preferenceId) =>
      isEqualStrings(preference.preferenceId, preferenceId),
    ),
  );

  if (!selectedPreference || !recipes || isEmptyArray(recipes)) {
    return null;
  }

  // const remainingDishCount =
  //   getDishCount({ week, year, portions }) - recipes.length;

  const dishCount = getDishCount({ week, year, portions });

  return (
    <Modal
      name="preselected-recipes-modal"
      open={open}
      onClose={onClose}
      size="md"
    >
      <Card className="w-full min-h-screen text-center md:min-h-fit">
        <div className="mx-4 my-8 md:mx-8">
          <h2 className="flex flex-col items-center justify-center gap-1 my-2 text-xl">
            <Image
              src={`https://ggfrontendassets.azureedge.net/publicassets/preference-icons/${selectedPreference.preferenceId.toUpperCase()}.svg`}
              width={32}
              height={32}
              alt={stripEmojis(selectedPreference.preferenceName)}
              sizes="32px"
            />
            <strong>{stripEmojis(selectedPreference.preferenceName)}</strong>
          </h2>

          <div>{selectedPreference.description}</div>

          <hr className="my-6 border-t-1.5 border-grey-1" />

          <PreselectedRecipesList recipes={recipes} />

          <div className="mt-6">
            <h3 className="text-lg">
              <strong>{intl.CHOOSE_BETWEEN_N_DISHES(dishCount)}</strong>
              {/* NOTE: Can be re-implemented with preference-dish-filtering */}
              {/* <strong>{intl.MORE_DISHES(remainingDishCount)}</strong> */}
            </h3>

            <div className="mt-2">
              {isLoggedIn
                ? intl.ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK
                : intl.SIGN_UP_AND_ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK}
            </div>
          </div>
        </div>
      </Card>

      <div className="sticky bottom-0 flex flex-col justify-center text-center bg-white">
        <div className="px-4 py-4 md:hidden md:px-20">
          <Button type="submit" onClick={onClose} primary full>
            {intl.CLOSE}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
