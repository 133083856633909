import {
  addWeeks,
  getDay,
  getISOWeekYear,
  setWeek,
  getWeek as getWeekOriginal,
  startOfWeekYear,
  setDay,
  addDays,
  addHours,
  addMinutes,
  addSeconds,
  getMonth,
  isSameDay,
} from "date-fns";

import { BRAND_NAME, WEEKDAY } from "@chef/constants";

import cutoffs from "@chef/constants/cutoffs";

type Cutoff = {
  week: number;
  year: number;
  date: string;
};

const commonOpts = {
  weekStartsOn: 6,
} as const;

export const getDateFromWeekYear = (args: {
  week: number;
  year: number;
  day?: number;
}) => {
  const { week, year, day } = args;

  let startDayOfYear = startOfWeekYear(new Date(year, 1, 0), commonOpts);

  if (day !== undefined) {
    startDayOfYear = setDay(startDayOfYear, day, commonOpts);
  }

  return setWeek(startDayOfYear, week, commonOpts);
};

/**
 * @deprecated use `getCutoffDateForWeek` instead
 */
export const getCutoffDateForWeekYear = (args: {
  week: number;
  year: number;
}) => {
  const { week, year } = args;

  return getCutoffDateForWeek({ week, year });
};

export const createDateFromWeekYearWithAddedWeeks = (args: {
  week: number;
  year: number;
  weeksToAdd: number;
}) => {
  const { weeksToAdd } = args;

  const date = getDateFromWeekYear({ ...args, day: 0 });

  return addWeeks(date, weeksToAdd);
};

export const nextDeliveryWeekDateObj = () => {
  const cutoffs = getCutoffData();

  const today = new Date();

  for (const cutoff of cutoffs) {
    const cursor = new Date(cutoff.date);

    if (cursor > today) {
      return cursor;
    }
  }

  throw new Error("No future cutoff found");
};

export const getWeek = (date: Date): number => {
  const cutoffs = getCutoffData();

  const entry = cutoffs.find((co) => +new Date(co.date) === +date);

  if (!entry) {
    return getWeekOriginal(date, commonOpts);
  }

  return entry.week;
};

export const getYear = (date: Date): number => {
  const cutoffs = getCutoffData();

  const entry = cutoffs.find((co) => +new Date(co.date) === +date);

  if (!entry) {
    // NOTE: this "hack" is needed because `getYear` inside of date-fns does not support options
    const day = date.getDay();

    // We need to check if today is sunday, if so add a week.
    // This is to ensure we get the correct week returned since we start week on sundays
    return getISOWeekYear(day === 0 ? addWeeks(date, 1) : date);
  }

  return entry.year;
};

export const getCutoffWeek = (date: Date): number => {
  const cutoffs = getCutoffData();

  // Find nearest future cutoff
  const entry = cutoffs.find((cutoff) => {
    const cutoffDate = new Date(cutoff.date);
    return cutoffDate > date;
  });

  return entry!.week;
};

export const getWeekYearSortableNumber = (
  args:
    | Date
    | {
        week: number;
        year: number;
      },
) => {
  let week: number;
  let year: number;

  if (args instanceof Date) {
    week = getWeek(args);
    year = getYear(args);
  } else {
    week = args.week;
    year = args.year;
  }

  return year + week / 100;
};

export const addTimeToDate = (
  date: Date,
  time: {
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
  },
) => {
  const { days, hours, minutes, seconds } = time;

  if (days) {
    date = addDays(date, days);
  }

  if (hours) {
    date = addHours(date, hours);
  }

  if (minutes) {
    date = addMinutes(date, minutes);
  }

  if (seconds) {
    date = addSeconds(date, seconds);
  }

  return date;
};

export const orderFromCurrentDay = (
  days: {
    day: WEEKDAY;
    open: boolean;
    hours: { close: string; open: string; _key: string }[];
  }[],
) => {
  const currentDay = getDay(new Date());
  const mod = (n: number, m: number) => ((n % m) + m) % m;

  if (!days || days.length === 0) {
    return [];
  }

  return [...days].sort((a, b) => {
    const aDay = mod(a.day - currentDay, 7);
    const bDay = mod(b.day - currentDay, 7);

    return aDay - bDay;
  });
};

/**
 * @description Checks if a week is within the next four weeks
 * @param args  { week: number; year: number }
 * @returns boolean
 */
export const isWithinNextFourWeeks = (args: { week: number; year: number }) => {
  const cutoffs = getCutoffData();
  const date = nextDeliveryWeekDateObj();

  const cursor = cutoffs.findIndex(
    (cutoff) => +new Date(cutoff.date) === +date,
  );

  const nextFourWeeks = [...Array(4)].map((_, i) => {
    return cutoffs[cursor + i];
  });

  return nextFourWeeks.some(
    (cutoff) => cutoff.week === args.week && cutoff.year === args.year,
  );
};

export const getPointSeasonInterval = (currentDate: Date) => {
  const cutoffs = getCutoffData();
  const currentMonth = getMonth(currentDate);

  const q1 = cutoffs.findIndex(
    (cutoff) => getMonth(new Date(cutoff.date)) === 0,
  );

  const q2 = cutoffs.findIndex(
    (cutoff) => getMonth(new Date(cutoff.date)) === 3,
  );

  const q3 = cutoffs.findIndex(
    (cutoff) => getMonth(new Date(cutoff.date)) === 6,
  );

  const q4 = cutoffs.findIndex(
    (cutoff) => getMonth(new Date(cutoff.date)) === 9,
  );

  if (currentMonth < 3) {
    return {
      firstDay: addDays(new Date(cutoffs[q1].date), 1),
      lastDay: new Date(cutoffs[q2].date),
    };
  }

  if (currentMonth < 6) {
    return {
      firstDay: addDays(new Date(cutoffs[q2].date), 1),
      lastDay: new Date(cutoffs[q3].date),
    };
  }

  if (currentMonth < 9) {
    return {
      firstDay: addDays(new Date(cutoffs[q3].date), 1),
      lastDay: new Date(cutoffs[q4].date),
    };
  }

  return {
    firstDay: addDays(new Date(cutoffs[q4].date), 1),
    lastDay: new Date(cutoffs[q1].date),
  };
};

// Check that the cutoff data is available and correct type
const getCutoffData = () => {
  if (!cutoffs) {
    throw new Error("Cutoff data not found");
  }

  const data = cutoffs.data;

  return data[BRAND_NAME];
};

export const getNextCutoff = (): Cutoff => {
  const cutoffs = getCutoffData();
  const currentDate = new Date();

  const nextCutoff = cutoffs.find((cutoff) => {
    const cutoffDate = new Date(cutoff.date);
    return cutoffDate > currentDate;
  });

  if (!nextCutoff) {
    throw new Error("Next cutoff date not found");
  }

  return nextCutoff;
};

export const getNextCutoffDate = (): Date => {
  return new Date(getNextCutoff().date);
};

export const getNextCutoffs = (n = 4): Cutoff[] => {
  const cutoffs = getCutoffData();
  const currentDate = new Date();

  const nextCutoffIdx = cutoffs.findIndex((cutoff) => {
    const cutoffDate = new Date(cutoff.date);
    return cutoffDate > currentDate;
  });

  if (nextCutoffIdx === -1) {
    throw new Error("Next cutoff date index not found");
  }

  return cutoffs.slice(nextCutoffIdx, nextCutoffIdx + n);
};

export const getNextCutoffDates = (n = 4): Date[] => {
  return getNextCutoffs(n).map((cutoff) => new Date(cutoff.date));
};

export const getCutoffDateForWeek = (args: { week: number; year: number }) => {
  const { week, year } = args;
  const cutoffs = getCutoffData();

  const cutoff = cutoffs.find(
    (cutoff) => cutoff.year === year && cutoff.week === week,
  );

  if (!cutoff) {
    throw new Error(
      `Cutoff date not found, week ${week} and year ${year} is out of range`,
    );
  }

  return new Date(cutoff.date);
};

export const getWeekYearCutoffByDate = (date: Date) => {
  const week = getWeek(date);
  const year = getYear(date);

  const theCutoff = findCutoff(week, year);

  if (!theCutoff) {
    throw new Error(
      `Cutoff date not found, week ${week} and year ${year} is out of range`,
    );
  }

  return new Date(theCutoff.date);
};

export const findCutoff = (week: number, year: number) => {
  const cutoffs = getCutoffData();

  return cutoffs
    .map((cutoff) => {
      const cutOffDate = new Date(cutoff.date);
      return {
        ...cutoff,
        // The week of cutoff object is not the same as the week of the cutoff date
        // It is the delivery week of the cutoff date, meaning its usually the week after the week of the cutoff date
        actualWeek: getWeek(cutOffDate),
        actualYear: getYear(cutOffDate),
      };
    })
    .find((cutoff) => cutoff.actualWeek === week && cutoff.actualYear === year);
};

export const isCutoffDate = (date: Date) => {
  const cutoffs = getCutoffData();

  return cutoffs.some((cutoff) => isSameDay(new Date(cutoff.date), date));
};
