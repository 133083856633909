import React from "react";
import clsx from "clsx";

import { Button, FloatingActionBar, RadioButtonOption } from "../components";
import PortionSizeSelector from "./PortionSizeSelector";
import CurrencyWithDiscount from "./CurrencyWithDiscount";
import { Language } from "../../types";

const intl = {
  no: {
    BUTTON_TEXT: "Gå videre",
    SEE_SELECTED_DISHES: "Se valgte retter",
    SELECT_ATLEAST_TEXT_PART_ONE: "Velg minst",
    SELECT_ATLEAST_ONE_TEXT: "rett",
    SELECT_ATLEAST_MORE_TEXT: "retter",
    SELECT_ATLEAST_TEXT_PART_TWO: "til",
    DINNER: "middager",
  },
  se: {
    BUTTON_TEXT: "Gå vidare",
    SEE_SELECTED_DISHES: "Se valda rätter",
    SELECT_ATLEAST_TEXT_PART_ONE: "Välj minst",
    SELECT_ATLEAST_ONE_TEXT: "rätt",
    SELECT_ATLEAST_MORE_TEXT: "rätter",
    SELECT_ATLEAST_TEXT_PART_TWO: "till",
    DINNER: "middagar",
  },
  dk: {
    BUTTON_TEXT: "Gå videre",
    SEE_SELECTED_DISHES: "Se valgte retter",
    SELECT_ATLEAST_TEXT_PART_ONE: "vælg mindst",
    SELECT_ATLEAST_ONE_TEXT: "ret",
    SELECT_ATLEAST_MORE_TEXT: "retter",
    SELECT_ATLEAST_TEXT_PART_TWO: "til",
    DINNER: "retter",
  },
};

interface FloatingEditorBarProps {
  price: number;
  discount: number;
  language: Language;
  basketCount: number;
  onShowBasket: () => void;
  minimumBasketCount: number;
  selectedPortion: number;
  portions: RadioButtonOption<number>[];
  onPortionChange: (value: number) => void;
  onButtonClick: () => void;
  isLoading?: boolean;
  id?: string;
}

const FloatingEditorBar = ({
  price,
  discount,
  language = "no",
  basketCount,
  onShowBasket = () => null,
  minimumBasketCount,
  selectedPortion,
  portions,
  onPortionChange,
  onButtonClick,
  isLoading,
  id,
}: FloatingEditorBarProps) => {
  const {
    BUTTON_TEXT,
    SELECT_ATLEAST_TEXT_PART_ONE,
    SELECT_ATLEAST_ONE_TEXT,
    SELECT_ATLEAST_MORE_TEXT,
    SELECT_ATLEAST_TEXT_PART_TWO,
    DINNER,
  } = intl[language];

  const mealsRequiredToSelect = minimumBasketCount - basketCount;

  return (
    <FloatingActionBar className="md:!py-3">
      <div
        className="flex items-center col-span-2 md:col-span-6 xl:col-span-9 md:justify-end"
        id={id}
      >
        {!isLoading && (
          <>
            <div className="hidden md:block" id="portion-size-selector">
              <PortionSizeSelector
                name="fab"
                options={portions}
                onChange={onPortionChange}
                value={selectedPortion}
                language={language}
              />
            </div>

            <div className="flex flex-col md:w-36 md:ml-6 md:text-right">
              {mealsRequiredToSelect > 0 ? (
                <div className="w-full text-sm text-error min-w-max">
                  {SELECT_ATLEAST_TEXT_PART_ONE} {mealsRequiredToSelect}{" "}
                  {mealsRequiredToSelect === 1
                    ? SELECT_ATLEAST_ONE_TEXT
                    : SELECT_ATLEAST_MORE_TEXT}{" "}
                  {SELECT_ATLEAST_TEXT_PART_TWO}
                </div>
              ) : (
                <button
                  className="w-full text-sm underline"
                  onClick={onShowBasket}
                >
                  <strong>
                    {selectedPortion} pers, {basketCount} {DINNER}
                  </strong>
                </button>
              )}

              <CurrencyWithDiscount
                total={mealsRequiredToSelect > 0 ? 0 : +price}
                discount={mealsRequiredToSelect > 0 ? 0 : +discount}
                className="md:justify-end"
                id="total-price"
                language={language}
              />
            </div>
          </>
        )}
      </div>

      <div
        className={clsx(
          isLoading && "md:mt-2",
          "flex items-center col-span-2 xl:col-span-3 md:col-start-7 xl:col-start-10",
        )}
      >
        <Button
          onClick={onButtonClick}
          disabled={mealsRequiredToSelect > 0}
          primary
          full
          loading={isLoading}
          id="floating-editor-bar-btn"
        >
          {BUTTON_TEXT}
        </Button>
      </div>
    </FloatingActionBar>
  );
};

export default FloatingEditorBar;
