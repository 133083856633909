import { BRAND_NAME, BRAND_PATHS, language } from "@chef/constants";
import { Calendar, Complaint, Dinner, User } from "@chef/icons/small";

const intl = (
  {
    no: {
      PLAN: "Planlegg",
      CALENDAR: "Kalender",
      ACCOUNT: "Konto",
      CUSTOMER_SERVICE: "Kundeservice",
    },
    se: {
      PLAN: "Planera",
      CALENDAR: "Kalender",
      ACCOUNT: "Konto",
      CUSTOMER_SERVICE: "Kundservice",
    },
    dk: {
      PLAN: "Planlæg",
      CALENDAR: "Kalender",
      ACCOUNT: "Konto",
      CUSTOMER_SERVICE: "Kundeservice",
    },
  } as const
)[language];

// did as separate objects for each brand in case
// if some of the brands have 5 items in the tab bar
export const tabBar = (
  {
    AMK: {
      items: [
        {
          label: intl.PLAN,
          href: BRAND_PATHS.MY_MENU_PAGE,
          icon: <Dinner className="w-6 h-6" />,
        },
        {
          label: intl.CALENDAR,
          href: BRAND_PATHS.DELIVERY_CALENDAR_UPCOMING_LINK,
          icon: <Calendar className="w-6 h-6" />,
        },
        {
          label: intl.ACCOUNT,
          href: BRAND_PATHS.MY_ACCOUNT_HREF,
          icon: <User className="w-10 h-10" />,
        },
        {
          label: intl.CUSTOMER_SERVICE,
          href: BRAND_PATHS.CONTACT_US_PAGE,
          icon: <Complaint className="w-6 h-6" />,
        },
      ],
    },
    GL: {
      items: [
        {
          label: intl.PLAN,
          href: BRAND_PATHS.MY_MENU_PAGE,
          icon: <Dinner className="w-6 h-6" />,
        },
        {
          label: intl.CALENDAR,
          href: BRAND_PATHS.DELIVERY_CALENDAR_UPCOMING_LINK,
          icon: <Calendar className="w-6 h-6" />,
        },
        {
          label: intl.ACCOUNT,
          href: BRAND_PATHS.MY_ACCOUNT_HREF,
          icon: <User className="w-10 h-10" />,
        },
        {
          label: intl.CUSTOMER_SERVICE,
          href: BRAND_PATHS.CONTACT_US_PAGE,
          icon: <Complaint className="w-6 h-6" />,
        },
      ],
    },
    LMK: {
      items: [
        {
          label: intl.PLAN,
          href: BRAND_PATHS.MY_MENU_PAGE,
          icon: <Dinner className="w-6 h-6" />,
        },
        {
          label: intl.CALENDAR,
          href: BRAND_PATHS.DELIVERY_CALENDAR_UPCOMING_LINK,
          icon: <Calendar className="w-6 h-6" />,
        },
        {
          label: intl.ACCOUNT,
          href: BRAND_PATHS.MY_ACCOUNT_HREF,
          icon: <User className="w-10 h-10" />,
        },
        {
          label: intl.CUSTOMER_SERVICE,
          href: BRAND_PATHS.CONTACT_US_PAGE,
          icon: <Complaint className="w-6 h-6" />,
        },
      ],
    },
    RN: {
      items: [
        {
          label: intl.PLAN,
          href: BRAND_PATHS.MY_MENU_PAGE,
          icon: <Dinner className="w-6 h-6" />,
        },
        {
          label: intl.CALENDAR,
          href: BRAND_PATHS.DELIVERY_CALENDAR_UPCOMING_LINK,
          icon: <Calendar className="w-6 h-6" />,
        },
        {
          label: intl.ACCOUNT,
          href: BRAND_PATHS.MY_ACCOUNT_HREF,
          icon: <User className="w-10 h-10" />,
        },
        {
          label: intl.CUSTOMER_SERVICE,
          href: BRAND_PATHS.CONTACT_US_PAGE,
          icon: <Complaint className="w-6 h-6" />,
        },
      ],
    },
  } as const
)[BRAND_NAME];
