import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  PickAndMixQuery,
  ProductsByCategoriesQuery,
} from "../graphql/generated";
import { IDeviationProduct, RootState } from "../";

export interface IRecipeModal {
  open: boolean;
  recipeId: number;
  pickAndMixProduct?: PickAndMixQuery["pickAndMix"][0] | null;
  misc?: {
    portionSize?: string;
    disableDropdown?: boolean;
    signup?: boolean;
    variationId?: string;
    showCTA?: boolean;
  } | null;
}

export interface PauseWeekModalProps {
  open: boolean;
  misc?: {
    deliveryDate?: string;
  } | null;
}

export interface IStandaloneProductModal {
  open: boolean;
  productByCategoryVariation:
    | ProductsByCategoriesQuery["productsByCategories"][0]["products"][0]["variations"][0]
    | null;
}

export interface ISubscribedStandaloneModal {
  open: boolean;
  deviationProduct: IDeviationProduct | null;
  productName: string | null;
  maxQtyAllowed: number | null;
}

export interface ICartModal {
  open: boolean;
  week: number | null;
  year: number | null;
}

export interface IDrawerModal {
  open: boolean;
}

export interface ILoginModal {
  open: boolean;
}

export interface ISchedulerModal {
  open: boolean;
  week: number;
  year: number;
}

export interface IResumeOngoingDeviationModal {
  open: boolean;
  week: number;
  year: number;
  deviationProducts: IDeviationProduct[];
  calendarBasketProducts: IDeviationProduct[];
  destinationUrl?: string;
}

export interface IFavoriteActionModal {
  open: boolean;
}

export interface IPreselectedRecipesModal {
  open: boolean;
  preferenceIds: string[];
}

export interface IPersonalizedRecipesModal {
  open: boolean;
  preferenceIds: string[];
}

const initialState = {
  recipe: {
    open: false,
    recipeId: 0,
    pickAndMixProduct: null,
    misc: null,
  } as IRecipeModal,

  deliveryPause: {
    open: false,
    misc: null,
  } as PauseWeekModalProps,

  standaloneProduct: {
    open: false,
    productByCategoryVariation: null,
  } as IStandaloneProductModal,

  subscribedStandalone: {
    open: false,
    deviationProduct: null,
    productName: null,
  } as ISubscribedStandaloneModal,

  cart: {
    open: false,
    week: null,
    year: null,
  } as ICartModal,

  menuDrawer: {
    open: false,
  },

  scheduler: {
    open: false,
    week: 0,
    year: 0,
  } as ISchedulerModal,

  cookies: {
    open: false,
  },

  drawer: {
    open: false,
  } as IDrawerModal,

  login: {
    open: false,
  } as ILoginModal,

  resumeOngoingDeviation: {
    open: false,
    week: 0,
    year: 0,
    deviationProducts: [],
    calendarBasketProducts: [],
  } as IResumeOngoingDeviationModal,

  favorite: {
    open: false,
  } as IFavoriteActionModal,

  preselectedRecipes: {
    open: false,
    preferenceIds: [],
  } as IPreselectedRecipesModal,

  personalizedRecipes: {
    open: false,
    preferenceIds: [],
  } as IPersonalizedRecipesModal,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setRecipeModal: (state, action: PayloadAction<IRecipeModal>) => {
      state.recipe = action.payload;
    },
    resetRecipeModal: (state) => ({ ...state, recipe: initialState.recipe }),

    setPreselectedRecipesModal: (
      state,
      action: PayloadAction<IPreselectedRecipesModal>,
    ) => {
      state.preselectedRecipes = action.payload;
    },
    resetPreselectedRecipesModal: (state) => ({
      ...state,
      preselectedRecipes: initialState.preselectedRecipes,
    }),

    setPersonalizedRecipesModal: (
      state,
      action: PayloadAction<IPersonalizedRecipesModal>,
    ) => {
      state.personalizedRecipes = action.payload;
    },

    resetPersnalizedRecipesModal: (state) => ({
      ...state,
      personalizedRecipes: initialState.personalizedRecipes,
    }),

    setPauseWeekModal: (state, action: PayloadAction<PauseWeekModalProps>) => {
      state.deliveryPause.open = !action.payload.open;
      state.deliveryPause = action.payload;
    },

    setStandaloneProductModal: (
      state,
      action: PayloadAction<IStandaloneProductModal>,
    ) => {
      state.standaloneProduct = action.payload;
    },

    resetStandaloneProductModal: (state) => ({
      ...state,
      standaloneProduct: initialState.standaloneProduct,
    }),

    setSubscribedStandaloneModal: (
      state,
      action: PayloadAction<ISubscribedStandaloneModal>,
    ) => {
      state.subscribedStandalone = action.payload;
    },
    resetSubscribedStandaloneModal: (state) => ({
      ...state,
      subscribedStandaloneProduct: initialState.subscribedStandalone,
    }),

    setWeekSchedulerEditorModal: (
      state,
      action: PayloadAction<Partial<ISchedulerModal>>,
    ) => {
      state.scheduler.open = action.payload.open || false;

      if (action.payload.week) {
        state.scheduler.week = action.payload.week;
      }

      if (action.payload.year) {
        state.scheduler.year = action.payload.year;
      }
    },

    toggleCartModal: (
      state,
      action: PayloadAction<Omit<ICartModal, "open">>,
    ) => {
      state.cart.open = !state.cart.open;

      state.cart.week = action.payload.week;
      state.cart.year = action.payload.year;
    },

    setCartModalOpen: (
      state,
      action: PayloadAction<Omit<ICartModal, "open">>,
    ) => {
      state.cart.open = true;

      state.cart.week = action.payload.week;
      state.cart.year = action.payload.year;
    },

    setCartModalClosed: (state) => {
      state.cart.open = false;
    },

    setMenuDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.menuDrawer.open = action.payload;
    },

    toggleMenuDrawer: (state) => {
      state.menuDrawer.open = !state.menuDrawer.open;
    },

    setCookiesModalOpen: (state, action: PayloadAction<boolean>) => {
      state.cookies.open = action.payload;
    },

    toggleCookiesModal: (state) => {
      state.cookies.open = !state.cookies.open;
    },

    toggleDrawerModal: (state) => {
      state.drawer.open = !state.drawer.open;
    },

    showDrawerModal: (state) => {
      state.drawer.open = true;
    },

    hideDrawerModal: (state) => {
      state.drawer.open = false;
    },

    showLoginModal: (state) => {
      state.login.open = true;
    },

    hideLoginModal: (state) => {
      state.login.open = false;
    },

    showFavoriteModal: (state) => {
      state.favorite.open = true;
    },

    hideFavoriteModal: (state) => {
      state.favorite.open = false;
    },

    setResumeOngoingDeviationModal: (
      state,
      action: PayloadAction<IResumeOngoingDeviationModal>,
    ) => {
      state.resumeOngoingDeviation = action.payload;
    },

    resetResumeOngoingDeviationModal: (state) => ({
      ...state,
      resumeOngoingDeviation: initialState.resumeOngoingDeviation,
    }),
  },
});

export const {
  setRecipeModal,
  resetRecipeModal,
  setStandaloneProductModal,
  resetStandaloneProductModal,
  setSubscribedStandaloneModal,
  resetSubscribedStandaloneModal,
  setWeekSchedulerEditorModal,
  toggleCartModal,
  setCartModalClosed,
  setCartModalOpen,
  setMenuDrawerOpen,
  toggleMenuDrawer,
  setCookiesModalOpen,
  toggleCookiesModal,
  setPauseWeekModal,
  toggleDrawerModal,
  showDrawerModal,
  hideDrawerModal,
  showLoginModal,
  hideLoginModal,
  showFavoriteModal,
  hideFavoriteModal,
  setResumeOngoingDeviationModal,
  resetResumeOngoingDeviationModal,
  setPreselectedRecipesModal,
  resetPreselectedRecipesModal,
  setPersonalizedRecipesModal,
  resetPersnalizedRecipesModal,
} = modalSlice.actions;

/**
 *
 * @deprecated Use toggleDialog from ./tracking/consents.ts instead
 */
// export const toggleCookiesModal = modalSlice.actions.toggleCookiesModal;

export const selectRecipeModal = (state: RootState) => state.modal.recipe;

export const selectPauseWeekModal = (state: RootState) =>
  state.modal.deliveryPause;

export const selectPauseWeekModalIsOpen = (state: RootState) =>
  state.modal.deliveryPause.open;

export const selectStandaloneProductModal = (state: RootState) =>
  state.modal.standaloneProduct;

export const selectSubscribedStandaloneModal = (state: RootState) =>
  state.modal.subscribedStandalone;

export const selectSchedulerModalIsOpen = (state: RootState) =>
  state.modal.scheduler.open;

export const selectSchedulerModal = (state: RootState) => state.modal.scheduler;

export const selectMenuDrawerIsOpen = (state: RootState) =>
  state.modal.menuDrawer.open;

export const selectCartModalIsOpen = (state: RootState) =>
  state.modal.cart.open;

export const selectCartModal = (state: RootState) => state.modal.cart;

export const selectCookieModalIsOpen = (state: RootState) =>
  state.modal.cookies.open;

export const selectDrawerModalIsOpen = (state: RootState) =>
  state.modal.drawer.open;

export const selectLoginModalIsOpen = (state: RootState) =>
  state.modal.login.open;

export const selectFavoriteModalIsOpen = (state: RootState) =>
  state.modal.favorite.open;

export const selectResumeOngoingDeviationModal = (state: RootState) =>
  state.modal.resumeOngoingDeviation;

export const selectPreselectedRecipesModal = (state: RootState) =>
  state.modal.preselectedRecipes;

export const selectPersonalizedRecipesModal = (state: RootState) =>
  state.modal.personalizedRecipes;

export default modalSlice.reducer;
