import { v4 as uuidv4 } from "uuid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getWeek, getYear, nextDeliveryWeekDateObj } from "@chef/helpers";
import { config, PaymentMethod } from "@chef/constants";

import { RootState } from "../..";

import {
  IApplicantInfo,
  IExternalIdentifier,
  IRegistrerUserArgs,
} from "../auth";

import { IAddDiscount } from "../discount/types";

interface IAddInitialSignupData {
  applicantInfo: IApplicantInfo;
}

export interface ISignupAffiliateId {
  da: string;
  at: string;
}

export interface ISignupGiftcardData {
  code: string;
  amount: number;
  type: number;
}

export interface ISignupMealboxData {
  productId: string;
  variationId: string;
  userInitiated: boolean;
}

export interface ISignupPersonalData {
  firstName: string;
  lastName: string;
  telephone: string;
  email: string;
  password: string;
  ssn?: string;
  allowEmailAdvertisment: boolean;
}

export interface ISignupDeliveryData {
  postalCode: string;
  postalCodeCity: string; // TODO: dispatch this in step 3
  address: string;
  firstDelivery?: string;
  deliveryTimeblock?: number;
  deliveryInstructionsToDriver?: string;
  doorCode?: string;
  frequency?: number;
  requiresPhysicalKey?: boolean;
  streetName?: string;
  streetNo?: number;
  letterExtra?: string;
  apartmentName?: string;
  flatNo?: string | null;
  floorNo?: string;
  validated?: string;
  addressUUID?: string;
  addressCode?: string;
  longitude?: number;
  latitude?: number;
  addressDigitalKeyProviderId?: string;
}

const initialState = {
  signupDataInitialized: false,
  weekAndYear: {
    week: getWeek(nextDeliveryWeekDateObj()),
    year: getYear(nextDeliveryWeekDateObj()),
  },
  applicantInfo: null as IApplicantInfo | null,
  checkoutId: "",

  affiliateIds: {
    da: "", // Digital advisor
    at: "", // Adtraction
  } as ISignupAffiliateId,

  addonSubscriptions: [] as string[],

  signicatSessionId: null as string | null,

  externalIdentifiers: [] as IExternalIdentifier[],

  coupon: null as IAddDiscount | null,
  giftcard: null as ISignupGiftcardData | null,

  paymentMethod: null as PaymentMethod | null,

  mealbox: config.order.defaultMealbox as ISignupMealboxData,

  personalData: {
    firstName: "",
    lastName: "",
    telephone: "",
    email: "",
    password: "",
    ssn: "",
    allowEmailAdvertisment: false,
  } as ISignupPersonalData,

  deliveryData: {
    postalCode: "",
    address: "",
    firstDelivery: "",
    deliveryTimeblock: 0,
    deliveryInstructionsToDriver: "",
    doorCode: "",
    frequency: 10,
    requiresPhysicalKey: false,
    streetName: "",
    streetNo: 0,
    letterExtra: "",
    apartmentName: "",
    flatNo: "",
    floorNo: "",
    validated: "",
    addressUUID: "",
    addressCode: "",
    longitude: 0,
    latitude: 0,
    addressDigitalKeyProviderId: "",
  } as ISignupDeliveryData,

  firstDeliveryTimeblockId: 0,
};

export const signupSlice = createSlice({
  name: "signupRtk",
  initialState,
  reducers: {
    addInitialSignupData: (
      state,
      action: PayloadAction<IAddInitialSignupData>,
    ) => {
      if (state.signupDataInitialized) {
        return;
      }

      state.signupDataInitialized = true;
      state.applicantInfo = action.payload.applicantInfo;
      state.checkoutId = uuidv4();
    },

    setSignupAffiliateId: (
      state,
      action: PayloadAction<Partial<ISignupAffiliateId>>,
    ) => {
      state.affiliateIds = {
        ...state.affiliateIds,
        ...action.payload,
      };
    },

    setSignupSignicatSessionId: (state, action: PayloadAction<string>) => {
      state.signicatSessionId = action.payload;
    },

    setSignupCoupon: (state, action: PayloadAction<IAddDiscount>) => {
      state.coupon = action.payload;
    },

    setSignupAddonSubscriptions: (state, action: PayloadAction<string[]>) => {
      const addonSubscriptions = new Set([
        ...state.addonSubscriptions,
        ...action.payload,
      ]);

      state.addonSubscriptions = [...addonSubscriptions];
    },

    setSignupGiftcard: (
      state,
      action: PayloadAction<Omit<ISignupGiftcardData, "type">>,
    ) => {
      state.giftcard = {
        ...action.payload,
        // addative type
        type: 2,
      };
    },

    addSignupExternalIdentifier: (
      state,
      action: PayloadAction<IExternalIdentifier>,
    ) => {
      if (
        state.externalIdentifiers.some(
          (extId) =>
            extId.externalPartnerId === action.payload.externalPartnerId,
        )
      ) {
        return;
      }

      state.externalIdentifiers.push(action.payload);
    },

    setSignupMealbox: (state, action: PayloadAction<ISignupMealboxData>) => {
      state.mealbox = action.payload;
    },

    updateSignupPersonalData: (
      state,
      action: PayloadAction<Partial<ISignupPersonalData>>,
    ) => {
      state.personalData = { ...state.personalData, ...action.payload };
    },

    setSignupDeliveryData: (
      state,
      action: PayloadAction<ISignupDeliveryData>,
    ) => {
      state.deliveryData = action.payload;
    },

    updateSignupDeliveryData: (
      state,
      action: PayloadAction<Partial<ISignupDeliveryData>>,
    ) => {
      state.deliveryData = { ...state.deliveryData, ...action.payload };
    },

    setFirstDeliveryTimeblockId: (state, action: PayloadAction<number>) => {
      state.firstDeliveryTimeblockId = action.payload;
    },

    resetSignupState: (state) => ({ ...state, ...initialState }),

    setPaymentMethod: (state, action: PayloadAction<PaymentMethod>) => ({
      ...state,
      paymentMethod: action.payload,
    }),
  },
});

// reset signup state on login
// addResetEndpointOnLogin(signupSlice.actions.resetSignupState); // This is causing a race condition for GL where the redirect if missing signup data useeffect is triggered before the user is redirected away from the page

export const {
  addInitialSignupData,
  setSignupAffiliateId,
  setSignupSignicatSessionId,
  setSignupCoupon,
  setSignupGiftcard,
  addSignupExternalIdentifier,
  setSignupMealbox,
  updateSignupPersonalData,
  setSignupDeliveryData,
  updateSignupDeliveryData,
  resetSignupState,
  setPaymentMethod,
  setFirstDeliveryTimeblockId,
  setSignupAddonSubscriptions,
} = signupSlice.actions;

export const selectSignupCoupon = (state: RootState) => state.signupRtk.coupon;
export const selectSignupGiftcard = (state: RootState) =>
  state.signupRtk.giftcard;

export const selectSignupCheckoutId = (state: RootState) =>
  state.signupRtk.checkoutId;

export const selectSignupApplicantInfo = (state: RootState) =>
  state.signupRtk.applicantInfo;

export const selectSignupAffiliateIds = (state: RootState) =>
  state.signupRtk.affiliateIds;

export const selectSignupMealbox = (state: RootState) =>
  state.signupRtk.mealbox;

export const selectSignupPersonalData = (state: RootState) =>
  state.signupRtk.personalData;

export const selectSignupDeliveryData = (state: RootState) =>
  state.signupRtk.deliveryData;

export const selectFirstDeliveryTimeblockId = (state: RootState) =>
  state.signupRtk.firstDeliveryTimeblockId;

export const selectSignupWeekAndYear = (state: RootState) =>
  state.signupRtk.weekAndYear;

export const selectSignupHasBeenInitialized = (state: RootState) =>
  state.signupRtk.signupDataInitialized;

export const selectSignupAddonSubscriptions = (state: RootState) =>
  state.signupRtk.addonSubscriptions;

export const selectSignupExternalIdentifiers = (state: RootState) =>
  state.signupRtk.externalIdentifiers;

export const selectRegistrationPostData = (
  state: RootState,
): Omit<
  IRegistrerUserArgs,
  "cardType" | "paymentMethod" | "paymentPartnerId"
> => {
  const salesPointId = state.signupRtk.coupon?.salesPointId;

  const data = {
    applicantInfo: state.signupRtk.applicantInfo!,
    firstName: state.signupRtk.personalData.firstName,
    lastName: state.signupRtk.personalData.lastName,
    birthdate: "01-01-1901", // might not be required

    telephone: state.signupRtk.personalData.telephone,
    email: state.signupRtk.personalData.email,
    password: state.signupRtk.personalData.password,
    personNumber: state.signupRtk.personalData.ssn,
    salesPointId: salesPointId ? parseInt(salesPointId) : undefined,
    company: config.companyId, // Todo confirm this is correct
    billingAddress: {
      PostalCode: state.signupRtk.deliveryData.postalCode,
      PostalCodeCity: state.signupRtk.deliveryData.postalCodeCity,
      Address: state.signupRtk.deliveryData.address,
      DoorCode: state.signupRtk.deliveryData.doorCode,
      deliveryTimeblock: state.signupRtk.deliveryData.deliveryTimeblock,
      deliveryInstructionsToDriver:
        state.signupRtk.deliveryData.deliveryInstructionsToDriver,
      alias: config.signup.defaultBillingAddressAlias,
      requiresPhysicalKey: state.signupRtk.deliveryData.requiresPhysicalKey,
      streetName: state.signupRtk.deliveryData.streetName,
      streetNo: state.signupRtk.deliveryData.streetNo,
      letterExtra: state.signupRtk.deliveryData.letterExtra,

      apartmentName: state.signupRtk.deliveryData.apartmentName,
      flatNo: state.signupRtk.deliveryData.flatNo,
      floorNo: state.signupRtk.deliveryData.floorNo,

      validated: state.signupRtk.deliveryData.validated,
      addressUUID: state.signupRtk.deliveryData.addressUUID,
      addressCode: state.signupRtk.deliveryData.addressCode,
      longitude: state.signupRtk.deliveryData.longitude,
      latitude: state.signupRtk.deliveryData.latitude,
      addressDigitalKeyProviderId:
        state.signupRtk.deliveryData.addressDigitalKeyProviderId,
    },
    basket: {
      shippingAddress: {
        PostalCode: state.signupRtk.deliveryData.postalCode,
        PostalCodeCity: state.signupRtk.deliveryData.postalCodeCity,
        Address: state.signupRtk.deliveryData.address,
        DoorCode: state.signupRtk.deliveryData.doorCode,
        deliveryTimeblock: state.signupRtk.deliveryData.deliveryTimeblock,
        deliveryInstructionsToDriver:
          state.signupRtk.deliveryData.deliveryInstructionsToDriver,
        alias: config.signup.defaultBasketAddressAlias,
        requiresPhysicalKey: state.signupRtk.deliveryData.requiresPhysicalKey,
        streetName: state.signupRtk.deliveryData.streetName,
        streetNo: state.signupRtk.deliveryData.streetNo,
        letterExtra: state.signupRtk.deliveryData.letterExtra,

        apartmentName: state.signupRtk.deliveryData.apartmentName,
        flatNo: state.signupRtk.deliveryData.flatNo,
        floorNo: state.signupRtk.deliveryData.floorNo,

        validated: state.signupRtk.deliveryData.validated,
        addressUUID: state.signupRtk.deliveryData.addressUUID,
        addressCode: state.signupRtk.deliveryData.addressCode,
        longitude: state.signupRtk.deliveryData.longitude,
        latitude: state.signupRtk.deliveryData.latitude,
        addressDigitalKeyProviderId:
          state.signupRtk.deliveryData.addressDigitalKeyProviderId,
      },
      deliveryInterval: state.signupRtk.deliveryData.frequency as number, // Todo is there a way to solve this in a type safe way?
      isDefault: true,
      isActive: true,
      subscribedProducts: [
        {
          subscribedVariation: state.signupRtk.mealbox.variationId,
          subscribedDeliveryInterval: state.signupRtk.deliveryData
            .frequency as number, // Todo is there a way to solve this in a type safe way?
          subscribedQuantity: 1,
        },
      ],
    },
    firstDelivery: state.signupRtk.deliveryData.firstDelivery as string, // Todo is there a way to solve this in a type safe way?
    discountCoupon: state.signupRtk.coupon
      ? state.signupRtk.coupon.couponCode
      : undefined,
    contacts: {
      email: true,
      socialMedia: true,
      sms: true,
      phone: true,
      print: true,
      door: true,
      cutOffNotice: true,
    },
    giftCardCode: state.signupRtk.giftcard?.code, // This is set to the signupRtk state by `useDiscount`
    affiliate: state.signupRtk.affiliateIds?.at || "",
    registrationProcessTypeId: "542e5466-3482-4479-adc1-950415d404e7",
    signicatSessionId: state.signupRtk.signicatSessionId,
    addonSubscriptions: state.signupRtk.addonSubscriptions.map((id) => ({
      id,
      isActive: true,
    })),
  };

  return data;
};

export * from "./session";
