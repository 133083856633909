import { useDispatch } from "react-redux";
import React, { CSSProperties } from "react";
import Image from "next/legacy/image";
import clsx from "clsx";

import { useTriggerAppRating } from "@chef/state-management/hooks";
import { MealCard, Skeleton } from "@chef/components";
import { language } from "@chef/constants";
import {
  OrderQuery,
  RecipesByIdsQuery,
  RecipesByProductIdListQuery,
  setRecipeModal,
  useRecipesAgreementRatingsQuery,
  useRecipesByIdsQuery,
  useRecipesByProductIdListQuery,
} from "@chef/state-management";
import { findFeaturedPortraitImage } from "@chef/utils/helpers/recipes";
import { nonNullable } from "@chef/utils/nonNullable";
import { useViewport } from "@chef/hooks";
import { isEmptyArray } from "@chef/utils/array";

import { RecipeTags } from "./RecipeTags";
import { RatingAndCommentContainer } from "./RatingAndCommentContainer";
import { FavoriteButton } from "./FavoriteButton";
import { FavoriteActionModal } from "./FavoriteActionModal";

type Recipes =
  | RecipesByProductIdListQuery["recipesByProductIdList"][number]["products"][number]["recipes"]
  | RecipesByIdsQuery["recipesByIds"]
  | OrderQuery["order"]["orderLines"][number]["recipes"];

type MealcardsProps = {
  week: number;
  year: number;
  portions: number;
  meals: number;
  isDelivered: boolean;
  className?: string;
  grid?: {
    cols: {
      sm: number;
      md: number;
      lg: number;
      xl: number;
    };
    rows: {
      sm: number;
      md: number;
      lg: number;
      xl: number;
    };
  };
} & (
  | {
      productIds: string[];
      recipeIds?: never;
      recipes?: never;
    }
  | {
      productIds?: never;
      recipeIds: number[];
      recipes?: never;
    }
  | {
      productIds?: never;
      recipeIds?: never;
      recipes: Recipes;
    }
);

interface MealProps {
  recipe: Recipes[number];
  portions?: number;
  rating?: number;
  comment?: string;
  displayRating?: boolean;
}

const Meal = ({
  recipe,
  portions,
  rating,
  comment,
  displayRating,
}: MealProps) => {
  const dispatch = useDispatch();
  const image = findFeaturedPortraitImage(recipe);

  const MealImage = React.memo(() => (
    <Image
      src={image.url}
      alt={recipe.recipeName}
      width={2}
      height={3}
      layout="responsive"
      objectFit="cover"
      sizes="(min-width: 1440px) 20vw, 50vw"
      className="rounded"
    />
  ));

  MealImage.displayName = "MealImage";

  return (
    <div className="relative">
      <FavoriteButton
        recipe={recipe}
        className="absolute z-10 shadow top-4 right-4"
      />
      <MealCard
        language={language}
        Image={MealImage}
        text={recipe.recipeName}
        addons={
          <RatingAndCommentContainer
            key={recipe.recipeId}
            initialRating={rating}
            recipeId={recipe.recipeId}
            initialComment={comment}
            display={displayRating}
          />
        }
        tags={[<RecipeTags key="0" recipe={recipe} />]}
        onClick={() =>
          dispatch(
            setRecipeModal({
              open: true,
              recipeId: recipe.recipeId,
              misc: { portionSize: portions?.toString() || "0" },
            }),
          )
        }
        className="min-h-full col-span-1"
        hideFooter
      />
    </div>
  );
};

export const Mealcards = ({
  productIds,
  recipeIds,
  recipes,
  week,
  year,
  portions,
  meals,
  isDelivered,
  className,
  grid,
}: MealcardsProps) => {
  const [breakpoint] = useViewport();

  const { data: recipesByProductIdListQuery } = useRecipesByProductIdListQuery(
    { productIds: productIds ?? [], week, year, range: 1 },
    { skip: !productIds || isEmptyArray(productIds) },
  );

  const { data: recipesByIdsQuery } = useRecipesByIdsQuery(
    { recipeIds: recipeIds ?? [] },
    { skip: !recipeIds || isEmptyArray(recipeIds) },
  );

  const { data: recipesAgreementRatingsQuery } =
    useRecipesAgreementRatingsQuery(undefined, { skip: !isDelivered });

  if (recipeIds) {
    recipes = recipesByIdsQuery?.recipesByIds;
  } else if (productIds) {
    recipes = recipesByProductIdListQuery?.recipesByProductIdList
      .flatMap((weekYear) => weekYear.products.flatMap((p) => p.recipes))
      .filter(nonNullable);
  }

  // Set up grid styles
  let gridStyle: CSSProperties["gridTemplate"];
  let cols = meals;
  let rows = 1;
  if (grid) {
    cols = Math.min(grid.cols[breakpoint], meals);
    rows = grid.rows[breakpoint];
    gridStyle = `repeat(${rows}, minmax(0, 1fr)) / repeat(${cols}, minmax(0, 1fr))`;
  }

  const recipesRatings =
    recipesAgreementRatingsQuery?.recipesAgreementRatings.recipeRatings || [];

  useTriggerAppRating({ recipes, recipesRatings });

  if (!recipes) {
    return (
      <div
        className={clsx(
          "grid items-start gap-2 md:gap-4 ",
          !grid && "xl:grid-cols-5 grid-cols-2 md:grid-cols-3",
        )}
      >
        {[...Array(5)].map((_, i) => (
          <div className="flex flex-col gap-2 aspect-2/5" key={i}>
            <div className="grow bg-grey-3" />
            <Skeleton width="33%" height={6} />
            <Skeleton width="67%" height={4} />
            <Skeleton width="50%" height={4} />
            <Skeleton width="100%" height={12} />
            <Skeleton width="67%" height={4} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      <div
        className={clsx(
          "grid items-start gap-2 md:gap-4",
          !grid && "grid-cols-2 xl:grid-cols-5 md:grid-cols-3",
          className,
        )}
        style={gridStyle ? { gridTemplate: gridStyle } : {}}
      >
        {recipes.slice(0, cols * rows).map((r) => {
          const rating = recipesRatings?.find(
            (rating) => rating.recipeId === r.recipeId,
          );
          return (
            <Meal
              key={r.recipeId}
              recipe={r}
              rating={rating?.rating ?? undefined}
              comment={rating?.comment || undefined}
              portions={portions}
              displayRating={isDelivered}
            />
          );
        })}
      </div>

      <FavoriteActionModal />
    </>
  );
};
