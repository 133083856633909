import clsx from "clsx";
import Image from "next/legacy/image";
import { motion } from "framer-motion";

import { Container } from "@chef/components";
import { BRAND_NAME, BRAND_NAME_FULL, LOGO } from "@chef/constants";

interface LogoImageProps {
  className?: string;
  loaderText?: string;
}

type PageLoaderProps = {
  loaderText?: string;
} & (WholeScreenLoaderProps | ModalLoaderProps);

type WholeScreenLoaderProps = {
  wholeScreen?: boolean;
  modal?: never;
};

type ModalLoaderProps = {
  wholeScreen?: never;
  modal?: boolean;
};

const LogoImage = ({ className, loaderText }: LogoImageProps) => (
  <>
    <div className={clsx("hidden md:block", className || "w-36")}>
      <Image
        src={LOGO.DEFAULT}
        alt={`${BRAND_NAME_FULL} logo`}
        width="148"
        height="32"
        layout="responsive"
        priority
      />
      {loaderText && (
        <p className="mt-2 text-sm text-center">
          <i>{loaderText}</i>
        </p>
      )}
    </div>
    <div className={clsx("md:hidden", className || "w-10")}>
      <Image
        src={LOGO.EMBLEM}
        alt={`${BRAND_NAME_FULL} logo`}
        width="32"
        height="32"
        layout="responsive"
        priority
      />
      {loaderText && (
        <p className="mt-2 text-sm text-center">
          <i>{loaderText}</i>
        </p>
      )}
    </div>
  </>
);

const LoaderLogo = ({ loaderText }: LogoImageProps) => {
  return (
    <>
      {BRAND_NAME === "GL" ? (
        <div>
          <GodtlevertLoaderIcon />
          {loaderText && (
            <p className="mt-2 text-sm text-center">
              <i>{loaderText}</i>
            </p>
          )}
        </div>
      ) : (
        <LogoImage
          loaderText={loaderText}
          className="w-32 animate-bounce md:w-64"
        />
      )}
    </>
  );
};

export const PageLoader = ({
  wholeScreen,
  loaderText,
  modal,
}: PageLoaderProps) => {
  if (wholeScreen) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-background/50 backdrop-blur-lg z-max">
        <LoaderLogo loaderText={loaderText} />
      </div>
    );
  }

  if (modal) {
    return (
      <div className="fixed flex items-center justify-center w-full h-screen max-w-screen-lg rounded-lg bg-background/50 backdrop-blur z-max">
        <LoaderLogo loaderText={loaderText} />
      </div>
    );
  }

  return (
    <Container outerClassName="flex-grow flex items-center justify-center bg-background">
      <LoaderLogo loaderText={loaderText} />
    </Container>
  );
};

const GodtlevertLoaderIcon = () => {
  return (
    <div className="w-20 h-20">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.4 283.4"
        fill="#FF604B"
      >
        <title>loader</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g id="Layer_1-3" data-name="Layer 1">
              <circle cx="141.7" cy="141.7" r="141.7" />
              <motion.path
                className="loader-path"
                d="M85,198l24-72S73,116,83,90s39-17,39-17,24.4,12.35,20,27c-6,20-35,4-4-23,0,0,27-15,42,12s-12,31-15,25,9.29-40,45-17c28,18-1,68-33,50l-16,69Z"
                fill="transparent"
                strokeWidth="13"
                stroke="white"
                strokeDasharray="700"
                transition={{ repeat: Infinity, duration: 3, ease: "linear" }}
                animate={{ strokeDashoffset: [660, -660] }}
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
