import clsx from "clsx";
import Image from "next/image";
import { useDispatch } from "react-redux";
import { getProductFeaturedImage } from "@chef/utils/helpers/product";
import { StandaloneCard, Tag } from "@chef/components";
import { BASE_64_1X1_GREY, language } from "@chef/constants";
import {
  BillingQuery,
  ICampaignProduct,
  IDeviationProduct,
  ProductsByCategoriesQuery,
  setStandaloneProductModal,
  setSubscribedStandaloneModal,
} from "@chef/state-management";
import {
  getStockData,
  getPriceOfProductByVariation,
  getDiscountedAmount,
  getAttribute,
} from "@chef/state-management/helpers";
import { isEqualStrings } from "@chef/utils/equal";
import { Discount } from "@chef/icons/small";
import { intl } from "./ProductsFromCategoriesCardGrid.Intl";

interface ProductsFromCategoriesCardGridProps {
  products: ProductsByCategoriesQuery["productsByCategories"][number]["products"];
  categoryId: ProductsByCategoriesQuery["productsByCategories"][number]["productCategoryId"];
  onAddToBasket: (product: IDeviationProduct) => void;
  onSubscribeToProduct?: (product: IDeviationProduct) => void;
  basket: IDeviationProduct[] | ICampaignProduct[];
  subscribedProducts?: BillingQuery["billing"]["baskets"][number]["basketProducts"];
  showDiscount?: boolean;
  isLoadingBilling?: boolean;
}

export const ProductsFromCategoriesCardGrid = ({
  products = [],
  categoryId,
  onAddToBasket = () => null,
  onSubscribeToProduct,
  basket = [],
  subscribedProducts = [],
  showDiscount = true,
  isLoadingBilling = false,
}: ProductsFromCategoriesCardGridProps) => {
  const dispatch = useDispatch();

  return (
    <div className="grid items-stretch gap-2 my-4 md:gap-4 md:grid-cols-3 lg:gap-4 lg:grid-cols-4 xl:grid-cols-5">
      {products.map((product, index) => {
        const { productId = "" } = product;
        return product.variations.map((variation) => {
          const { name, variationId } = variation;

          const imageUrl = getProductFeaturedImage(variation);
          const variationInBasket = basket?.find(
            (b) =>
              isEqualStrings(b.productId, productId) &&
              isEqualStrings(b.variationId, variationId),
          );

          const variationCount = variationInBasket?.quantity || 0;

          const subscribedProduct = subscribedProducts.find(
            (p) => p.variation.productId === product.productId,
          );
          const subscribedQuantity = subscribedProduct?.quantity;

          const isAllowedToSubscribe = isEqualStrings(
            getAttribute("isAllowedToSubscribe", variation),
            "true",
          );

          const maxVariationQtyAllowed = +(
            getAttribute("max_variation_qty_allowed", variation) || 1
          );

          const price = getPriceOfProductByVariation(variation);
          const discountedAmount = getDiscountedAmount(variation);
          const isOnSale = !!discountedAmount;

          const { stockState, maxPurchasableAmount } = getStockData({
            addedQuantity: variationCount,
            variation,
          });

          return (
            <div
              className="relative md:mb-14"
              key={product.productId + variationId}
            >
              {isOnSale && (
                <Discount className="absolute z-10 w-7.5 h-7.5 text-white drop-shadow fill-error -top-3 -right-3" />
              )}
              <StandaloneCard
                key={product.productId + variationId}
                name={name}
                price={price}
                id={`${categoryId}:${product.productId}:${variationId}`}
                discount={showDiscount ? discountedAmount : undefined}
                CampaignTag={({ className }) => (
                  <div className={clsx(className, !isOnSale && "hidden")}>
                    <Tag className="normal-case bg-highlight">
                      {intl.DISCOUNTED_DISHES}
                    </Tag>
                  </div>
                )}
                onClick={() =>
                  dispatch(
                    setStandaloneProductModal({
                      open: true,
                      productByCategoryVariation: variation,
                    }),
                  )
                }
                onChangeSubscribed={() =>
                  dispatch(
                    setSubscribedStandaloneModal({
                      open: true,
                      deviationProduct: {
                        productId: product.productId,
                        productTypeId: product.productTypeId,
                        quantity: subscribedQuantity || 0,
                        variationId: variationId,
                        price,
                      },
                      productName: product.name,
                      maxQtyAllowed: maxVariationQtyAllowed,
                    }),
                  )
                }
                Image={() => (
                  <Image
                    alt={variation.name}
                    src={imageUrl || BASE_64_1X1_GREY}
                    fill
                    className="object-cover rounded-l md:rounded-none md:rounded-t"
                    sizes="(min-width: 768px) 20vw, 35vw"
                    priority={index < 6}
                  />
                )}
                onDecrementItem={() =>
                  onAddToBasket({
                    productId: product.productId,
                    productTypeId: product.productTypeId,
                    quantity: variationCount - 1,
                    variationId: variationId,
                    price,
                  })
                }
                onIncrementItem={() =>
                  onAddToBasket({
                    productId: product.productId,
                    productTypeId: product.productTypeId,
                    quantity: variationCount + 1,
                    variationId: variationId,
                    price,
                  })
                }
                onAddToSubscription={
                  onSubscribeToProduct
                    ? () =>
                        onSubscribeToProduct({
                          productId: product.productId,
                          productTypeId: product.productTypeId,
                          quantity: !subscribedProduct ? variationCount : 0,
                          variationId: variationId,
                          price,
                        })
                    : undefined
                }
                isSubscribedTo={!!subscribedProduct}
                subscribedQuantity={subscribedQuantity}
                isAllowedToSubscribe={isAllowedToSubscribe}
                isLoadingBilling={isLoadingBilling}
                count={variationCount}
                maxCount={maxPurchasableAmount || 0}
                stockState={stockState || undefined}
                language={language}
                className="md:h-full"
              />
            </div>
          );
        });
      })}
    </div>
  );
};
