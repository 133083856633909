import { useEffect, useRef } from "react";

import { usePersistentState } from "@chef/hooks";
import { average, isEmptyArray } from "@chef/utils/array";

import {
  OrderQuery,
  RecipesByIdsQuery,
  RecipesByProductIdListQuery,
} from "../graphql/generated";

type Recipes =
  | RecipesByProductIdListQuery["recipesByProductIdList"][number]["products"][number]["recipes"]
  | RecipesByIdsQuery["recipesByIds"]
  | OrderQuery["order"]["orderLines"][number]["recipes"];

interface UseTriggerAppRating {
  recipes: Recipes | undefined;
  recipesRatings: { recipeId: number; rating: number; comment: string }[];
}

export interface AppRatingInfo {
  readyToTrigger: boolean;
  hasBeenTriggered: boolean;
}

export const useTriggerAppRating = ({
  recipes,
  recipesRatings,
}: UseTriggerAppRating) => {
  const initialAverageRating = useRef<number | null>(null);
  const [appRatingInfo, setAppRatingInfo] = usePersistentState<AppRatingInfo>(
    "app_rating_info",
    { readyToTrigger: false, hasBeenTriggered: false },
  );

  const currentOrderRatings =
    recipes?.map((r) => {
      const rating = recipesRatings.find(
        (rating) => rating.recipeId === r.recipeId,
      );
      return rating?.rating ?? 0;
    }) ?? [];

  const allOrderRecipesRated = currentOrderRatings?.every(
    (rating) => rating > 0,
  );

  const averageRating = average(currentOrderRatings);

  useEffect(() => {
    if (isEmptyArray(recipes) || isEmptyArray(recipesRatings)) {
      return;
    }

    if (initialAverageRating.current === null && averageRating > 0) {
      initialAverageRating.current = averageRating;
    }

    if (
      allOrderRecipesRated &&
      averageRating > 4 &&
      !appRatingInfo.readyToTrigger &&
      !appRatingInfo.hasBeenTriggered
    ) {
      setAppRatingInfo({
        readyToTrigger: true,
        hasBeenTriggered: false,
      });
    }
  }, [allOrderRecipesRated, averageRating, appRatingInfo, setAppRatingInfo]);
};

type AppRatingEndpoint = "on-request-user-rate-app";

export const useAppRatingAction = (
  isApp: boolean,
  sendAppMessage: (
    endpoint: AppRatingEndpoint,
    payload: Record<string, unknown>,
    options?: Record<string, unknown>,
  ) => Promise<unknown>,
) => {
  const [, setAppRatingInfo] = usePersistentState<AppRatingInfo>(
    "app_rating_info",
    { readyToTrigger: false, hasBeenTriggered: false },
  );

  const triggerAppRatingAction = async () => {
    const hasBeenTriggered = JSON.parse(
      localStorage.getItem("app_rating_info") || "{}",
    )?.hasBeenTriggered;

    if (!hasBeenTriggered && isApp) {
      setTimeout(async () => {
        await sendAppMessage("on-request-user-rate-app", {});

        setAppRatingInfo({
          readyToTrigger: true,
          hasBeenTriggered: true,
        });
      }, 600); // Add delay to allow notification to show
    }
  };

  return { triggerAppRatingAction };
};
