import { useSelector } from "react-redux";

import { PRODUCT_CATEGORY_IDS } from "@chef/constants";
import { isStandaloneProduct, isAddonProduct } from "@chef/helpers";
import {
  IDeviationBasket,
  selectDeviationProducts,
  useProductCategoriesQuery,
  useProductsByCategoriesQuery,
} from "@chef/state-management";
import {
  getPriceOfProductByVariation,
  getStandaloneProductCategoryIdsFromProductCategories,
  getVariationFromProductsByCategories,
} from "@chef/state-management/helpers";
import {
  useExtendedBasketData,
  useUpdateBasketPickAndMixDeviation,
} from "@chef/state-management/hooks";

import { Groceries } from "../shared/Groceries";

interface DeviationGroceriesProps {
  deviation: IDeviationBasket;
  initiallyExpanded?: boolean;
  allowGroceryRemoval?: boolean;
}

export const DeviationGroceries = ({
  deviation,
  initiallyExpanded,
  allowGroceryRemoval,
}: DeviationGroceriesProps) => {
  const { week, year } = deviation;

  const { data: productCategoriesData } = useProductCategoriesQuery();

  const { updateBasketPickAndMixDeviation } =
    useUpdateBasketPickAndMixDeviation({ week, year });
  const selectedProducts = useSelector(selectDeviationProducts({ week, year }));

  const { data: productsByCategoriesData } = useProductsByCategoriesQuery(
    {
      categoryIds: [
        PRODUCT_CATEGORY_IDS.FINANCIAL,
        PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_IN,
        ...getStandaloneProductCategoryIdsFromProductCategories(
          productCategoriesData?.productCategories || [],
        ),
      ],
      week,
      year,
    },
    { skip: !productCategoriesData?.productCategories },
  );

  const groceriesWithVariation = deviation.products
    .filter((p) => isStandaloneProduct(p) || isAddonProduct(p))
    .map((bp) => ({
      ...bp,
      variation: getVariationFromProductsByCategories({
        productsByCategories:
          productsByCategoriesData?.productsByCategories || [],
        variationId: bp.variationId,
        productId: bp.productId,
      }),
    }));

  const { data: extendedBasketData } = useExtendedBasketData({ week, year });

  if (
    !groceriesWithVariation ||
    !productsByCategoriesData ||
    !selectedProducts ||
    !extendedBasketData
  ) {
    return null;
  }

  const portions = extendedBasketData.portions;

  const removeProductFromBasket = (variationId: string) => {
    updateBasketPickAndMixDeviation({
      basketProducts: [
        ...selectedProducts.filter((p) => p.variationId !== variationId),
      ],
      portions,
    });
  };

  const reduceProductInBasketCount = (variationId: string) => {
    updateBasketPickAndMixDeviation({
      basketProducts: [
        ...selectedProducts.map((p) =>
          p.variationId === variationId
            ? {
                ...p,
                quantity: p.quantity - 1,
              }
            : p,
        ),
      ],
      portions,
    });
  };

  const onProductRemoved = (variationId: string) => {
    const product = selectedProducts.find((p) => p.variationId === variationId);

    if (product?.quantity === 1) {
      removeProductFromBasket(variationId);
    }

    if (product?.quantity && product.quantity > 1) {
      reduceProductInBasketCount(variationId);
    }
  };

  return (
    <Groceries
      initiallyExpanded={initiallyExpanded}
      groceries={groceriesWithVariation.map((grocery) => ({
        name: grocery.variation?.product?.name || "",
        quantity: grocery.quantity,
        price: grocery.variation
          ? getPriceOfProductByVariation(grocery.variation) * grocery.quantity
          : 0,
        onRemove: allowGroceryRemoval
          ? () => onProductRemoved(grocery.variationId)
          : undefined,
      }))}
    />
  );
};
