import { IDeviationBasket } from "@chef/state-management";
import { PRODUCT_TYPE_IDS } from "@chef/constants";
import { useExtendedBasketData } from "@chef/state-management/hooks";

import { DeviationMeals } from "./DeviationMeals";
import { DeviationGroceries } from "./DeviationGroceries";
import { PermanentDiscount } from "../shared/PermanentDiscount";
import { TemporaryDiscount } from "../shared/TemporaryDiscount";
import { Credits } from "../shared/Credits";
import { DeviationDelivery } from "./DeviationDelivery";
import { DeviationSummary } from "./DeviationSummary";

interface DeviationShoppingCartProps {
  deviation: IDeviationBasket;
  allowMealRemoval?: boolean;
  allowGroceryRemoval?: boolean;
  initiallyExpandMealSection?: boolean;
  initiallyExpandGrocerySection?: boolean;
  showMealImages?: boolean;
}

export const DeviationShoppingCart = ({
  deviation,
  allowMealRemoval,
  allowGroceryRemoval,
  initiallyExpandMealSection,
  initiallyExpandGrocerySection,
  showMealImages,
}: DeviationShoppingCartProps) => {
  const { week, year } = deviation;

  const { data: extendedBasketData } = useExtendedBasketData({ week, year });

  const minMeals = extendedBasketData.minMeals;

  const selectedPickAndMix = deviation.products.filter(
    ({ productTypeId }) => productTypeId === PRODUCT_TYPE_IDS.PICKANDMIX,
  );

  const missingDishCount = minMeals - selectedPickAndMix.length;

  return (
    <div>
      <DeviationMeals
        initiallyExpanded={initiallyExpandMealSection}
        deviation={deviation}
        allowMealRemoval={allowMealRemoval}
        showImages={showMealImages}
        minMeals={minMeals}
      />

      <DeviationGroceries
        deviation={deviation}
        initiallyExpanded={initiallyExpandGrocerySection}
        allowGroceryRemoval={allowGroceryRemoval}
      />

      <PermanentDiscount />

      <TemporaryDiscount />

      <Credits />

      <DeviationDelivery deviation={deviation} />

      {missingDishCount <= 0 && <DeviationSummary deviation={deviation} />}
    </div>
  );
};
